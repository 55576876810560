import type { AxiosError } from "axios";
import Axios from "axios";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import useSWR from "swr";
import { NumberParam, useQueryParams } from "use-query-params";
import { useAuthContext } from "../../../../components/Auth";
import {
  CancelButton,
  EditButton,
  GoBackButton,
  PrimaryButtonLarge,
} from "../../../../components/Buttons/Buttons";
import type { BlockLeadFormData } from "../../../../components/Buttons/Buttons";
import { CreateNewTenantFormContainer } from "../../../../components/CreateNewTenantForm/CreateNewTenantFormContainer";
import { ErrorPlaceholder } from "../../../../components/Error";
import { IDDesktop } from "../../../../components/IDs/IDs";
import { InfoBlockExtraSmall } from "../../../../components/InfoBlocks/InfoBlocks";
import {
  HeaderContainer,
  HeaderLeft,
  HeaderRight,
  SubheaderInformationContainer,
} from "../../../../components/Layout/Layout";
import { Loader } from "../../../../components/Loader/Loader";
import { MultiLineBlock } from "../../../../components/LocationsList/LocationsList";
import { Modal } from "../../../../components/Modal/Modal";
import { Notifications } from "../../../../components/Notifications/NotificationsContext";
import { StatusRight } from "../../../../components/Status/Status";
import { TransactionItem } from "../../../../components/TransactionItem/TransactionItem";
import { TransactionItemInfoBlock } from "../../../../components/TransactionItem/TransactionItemInfoBlock";
import { TransactionItemTopLeft } from "../../../../components/TransactionItem/TransactionItemTopLeft";
import {
  ClickableLink,
  H3,
  InnerTitleText,
  SubheaderContentText,
} from "../../../../components/Typography/Typography";
import { endpoints } from "../../../../endpoints";
import {
  Card,
  ContentWrapper,
  FullWidthHorizontalSeparator,
  PageHeader,
  PageTitle,
  PageWrapper,
} from "../../../../layout/portalPageLayout";
import type {
  BlockLeadData,
  IAddress,
  Lead,
  LeadRejectionArgsSchema,
  LocationState,
  Tenant,
  UserTypeChip,
} from "../../../../types/types";
import { formateInternationalPhoneNumber } from "../../../../util/phone";
import {
  providePrivatePageProps,
  useRoutePath,
} from "../../../../util/Routing";
import {
  formatDateTime,
  makeUrlWithParams,
  useInAppNotifications,
  useStoreState,
} from "../../../../util/util";
import { getLeadStatusDetails } from "../leadsUtilities";
import { WarningMessageBox } from "../../../../components/Form/Form";
import { CreateNewUser } from "../../../../components/CreateNewUser/CreateNewUser";
import { WarningIcon } from "../../../../components/Icons/Icons";
import { WideDetails } from "../../../../layout/shared/DetailPageLayout/DetailPageLayout";
import { ContactInfoBlockSmall } from "../../../../components/ContactInfoBlockSmall/ContactInfoBlockSmall";
import { LeadNotes } from "../LeadNotes";
import type { HandleLeadNoteSubmitProps } from "../LeadNotes";
import { LeadRejectionModal } from "../LeadRejectionModal";
import { LeadRejectionCard } from "../LeadRejectionCard";
import { SlideOut } from "../../../../components/SlideOut/SlideOut";
import { AssignLeadToUser } from "../AssignLeadToUser";
import theme from "../../../../theme";
import { DropDownButton } from "../../../../components/DropDownButton/DropDownButton";
import ReactTooltip from "react-tooltip";
import { LeadToBlockModal } from "../LeadToBlockModal";
import { BlockLeadToUserModal } from "../BlockLeadToUserModal";
import {
  ActiveWarningMessage,
  NotificationContainer,
} from "../../SellerQuoteDetailPage/SellerQuoteDetailPageContent";
import type { blockLeadLabels } from "../../../admin/SellerAdmin/SellerAdminSampleRequestLeadDetail/SellerAdminSampleRequestLeadDetail";

const ButtonSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const EmailContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Details = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 250px);
  grid-gap: 30px;
`;

const NameAndIcon = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 2px;
`;

export const RightButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;
`;

/**
 * A shared page component that shows details for a single quote request
 * that came from a guest user that does not have an account yet (a new
 * lead).
 *
 * Used for seller admin users.
 */
export const QuoteRequestLeadDetailPage = providePrivatePageProps(
  ({ user }) => {
    // The role can be SELLER_ADMIN.
    const {
      roleIsSomeKindOfSeller,
      roleIsDistributor,
      roleIsDistributorAdmin,
    } = useAuthContext();
    const {
      storefront_metadata: { tenant_type },
      storefront_id,
    } = useStoreState();

    const userIsProducerSeller =
      roleIsSomeKindOfSeller && tenant_type === "Producer";

    const userIsDistributorSeller = roleIsDistributor || roleIsDistributorAdmin;
    const { lead_id } = useParams<{ lead_id: string }>();

    const [query] = useQueryParams({
      offset: NumberParam,
      perPage: NumberParam,
    });

    const {
      data: lead,
      error,
      mutate: mutateLead,
    } = useSWR<Lead, AxiosError>(
      `/v1/storefronts/${storefront_id}/leads/${lead_id}`
    );

    const { t } = useTranslation();

    const history = useHistory();
    const location = useLocation();
    const { adminPath } = useRoutePath();
    const { notifySuccess, notifyError } = useContext(Notifications);
    const [tenantTypeToBeCreated, setTenantTypeToBeCreated] =
      useState<"Buyer" | "Distributor">("Buyer");

    const [showLeadRejectionModal, setShowLeadRejectionModal] = useState(false);
    const closeLeadRejectionModal = () => setShowLeadRejectionModal(false);
    const openLeadRejectionModal = () => setShowLeadRejectionModal(true);

    const [showCreateCustomerModal, setShowCreateCustomerModal] =
      useState(false);
    const closeCreateCustomerModal = () => setShowCreateCustomerModal(false);
    const openCreateCustomerModal = () => setShowCreateCustomerModal(true);

    const [showLeadAssignmentModal, setShowLeadAssignmentModal] =
      useState(false);
    const closeLeadAssignmentModal = () => setShowLeadAssignmentModal(false);
    const openLeadAssignmentModal = () => setShowLeadAssignmentModal(true);

    const [showAddToCustomerModal, setShowAddToCustomerModal] = useState(false);

    const [showConvertLeadModal, setShowConvertLeadModal] = useState(false);
    const [dropDownName, setDropDownName] = useState("");

    const openConvertToLeadModal = () => {
      setShowConvertLeadModal(true);
    };

    const handleCancel = () => {
      setShowConvertLeadModal(false);
    };

    const { mutateNotifications } = useInAppNotifications(storefront_id, user);
    useEffect(() => {
      if (lead) {
        mutateNotifications();
      }
    }, [lead, mutateNotifications]);

    const { data: blockLeadData, mutate: mutateBlockLeadData } = useSWR<
      BlockLeadData,
      AxiosError
    >(`/v1/storefronts/${storefront_id}/leads/${lead_id}/blocks`);

    const { data: spamStatusData } = useSWR(
      `/v1/storefronts/${storefront_id}/leads/${lead_id}/spam-status`
    );

    const isGlobalLeadSpam = spamStatusData?.is_spam;
    const isCurrentLeadSpam = blockLeadData?.items?.some(
      (block) => block.is_spam
    );
    const isActionDisable: boolean = !!blockLeadData?.items?.length;

    // For when the user is a producer seller admin and for forwarded leads:
    // The producer's lead has a `children` property that contains the
    // distributor's copy of the lead. We use the seller ID from the
    // distributor's copy of the lead to get the contact details for the
    // distributor tenant, so we can display those in the "Forwarded to"
    // section.
    const childLead = lead?.children?.[0];
    const childLeadSellerId = childLead?.seller_id;

    const { data: forwardedToTenant } = useSWR<Tenant>(
      userIsProducerSeller && childLeadSellerId
        ? endpoints.v1_storefronts_id_tenants_id(
            storefront_id,
            childLeadSellerId
          )
        : lead?.status === "Added as User"
        ? endpoints.v1_storefronts_id_tenants_id(
            storefront_id,
            lead.parent_customer_id
          )
        : null
    );
    const forwardedToContact = forwardedToTenant?.primary_contact;

    const hasTransactionData = Boolean(
      lead?.address && lead?.billing_address && lead?.required_eta
    );

    const onConvertToCustomer = (tenantId: string) => {
      closeCreateCustomerModal();

      history.push(
        `${adminPath}/${
          tenantTypeToBeCreated === "Buyer" ? "tenants" : "distributors"
        }/${tenantId}`
      );
    };

    const handleRejectQuote = async (messages: LeadRejectionArgsSchema) => {
      try {
        const response = await Axios.post(
          `/v1/tenants/${user.tenant_id}/leads/${lead_id}/reject`,
          messages
        );
        if (response.status === 201) {
          notifySuccess(t("Lead rejected successfully"));
          mutateLead();
          closeLeadRejectionModal();
        }
      } catch (error) {
        notifyError(
          t("There was an error rejecting the quote, please try again."),
          {
            error,
          }
        );
      }
    };

    const onLeadNoteSubmit = async ({
      note,
      onSuccess,
    }: HandleLeadNoteSubmitProps) => {
      try {
        await Axios.post(
          `/v1/storefronts/${storefront_id}/leads/${lead_id}/notes`,
          {
            type: "quote_request",
            message: note,
          }
        );
        onSuccess();
        mutateLead();
        notifySuccess(t("note added successfully"));
      } catch (error) {
        notifyError(t("There was an error adding the note"));
      }
    };

    const openAddUserToCustomerModal = () => setShowAddToCustomerModal(true);
    const closeAddUserToCustomerModal = () => setShowAddToCustomerModal(false);

    const requestedEta = lead?.required_eta
      ? moment(lead.required_eta).format("MMMM DD, YYYY")
      : undefined;

    const getBackLinkState = () => {
      const listState = (location.state as LocationState)?.listState;
      return listState ? { listState } : undefined;
    };

    const getBackLinkParams = () => {
      const { tabIndex, offset, perPage } =
        (location.state as LocationState)?.listState || {};
      return {
        tab: tabIndex !== undefined ? tabIndex : undefined,
        offset: offset !== undefined ? offset : undefined,
        perPage: perPage !== undefined ? perPage : undefined,
      };
    };

    const handleBackClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      const state = getBackLinkState();
      const params = getBackLinkParams();
      const search = makeUrlWithParams("", params);
      history.push({
        pathname: `${adminPath}/leads`,
        search,
        state,
      });
    };

    const PageWrapperEtc = ({ children }: { children: React.ReactNode }) => (
      <PageWrapper style={{ maxWidth: "932px" }}>
        <Link
          to={makeUrlWithParams(`${adminPath}/leads`, {
            offset: query.offset,
            perPage: query.perPage,
          })}
          onClick={handleBackClick}
        >
          <GoBackButton text={"Leads"}></GoBackButton>
        </Link>
        {children}
      </PageWrapper>
    );

    const maxEmailLength = 33;
    const isEmailTooLong = lead && lead.buyer_email?.length > maxEmailLength;

    const handleBlockLead = async (
      data: BlockLeadFormData,
      setShow: (show: boolean) => void
    ) => {
      const blockLeadLabels: blockLeadLabels = {
        block_domain: "domain",
        block_email: "email",
        block_phone_number: "phone",
      };

      const store: string[] = Object.entries(data)
        .filter(([key, value]) => key !== "report_spam" && value)
        .map(([key]) => blockLeadLabels[key as keyof blockLeadLabels]);

      try {
        const response = await Axios.post(
          `/v1/storefronts/${storefront_id}/leads/${lead_id}/blocks`,
          {
            block_types: store,
            is_spam: data?.report_spam,
          }
        );
        if (response?.status === 201) {
          notifySuccess(t("Block lead added successfully"));
          mutateBlockLeadData();
          mutateLead();
          setShow(false);
        }
      } catch (error) {
        notifyError(t("There was an error adding the block lead"));
      }
    };

    const handleModalOpening = (dropDown: string) => {
      if (isGlobalLeadSpam) {
        openConvertToLeadModal();
      } else {
        if (dropDown === "convertToNew") {
          openCreateCustomerModal();
        } else if (dropDown === "addToExisting") {
          openAddUserToCustomerModal();
        }
      }
    };

    const handleDropdownClick = (item: string, dropDownName: string) => {
      const tenantType = item === "Customer" ? "Buyer" : "Distributor";
      setTenantTypeToBeCreated(tenantType);
      setDropDownName(dropDownName);

      handleModalOpening(dropDownName);
    };

    const handleConvertToLead = () => {
      setShowConvertLeadModal(false);
      if (dropDownName === "addToExisting") {
        openAddUserToCustomerModal();
      } else if (dropDownName === "convertToNew") {
        openCreateCustomerModal();
      }
    };

    if (error) {
      return (
        <PageWrapperEtc>
          <ErrorPlaceholder
            message={t("There was an error loading the quote lead")}
          />
        </PageWrapperEtc>
      );
    }
    if (!lead && !error) {
      return (
        <PageWrapperEtc>
          <Loader isLoading={true} />
        </PageWrapperEtc>
      );
    }
    if (lead) {
      const { text: statusText, color: statusColor } = getLeadStatusDetails(
        lead.status,
        t
      );

      const onLeadAssignmentSuccess = (state: "unassigned" | "assigned") => {
        closeLeadAssignmentModal();
        mutateLead();
        state === "assigned"
          ? notifySuccess(t("Lead assigned successfully"))
          : notifySuccess(t("Lead unassigned successfully"));
      };

      const onLeadAssignmentFailure = (error: unknown) => {
        notifyError(t("There was an error assigning the lead"), { error });
      };

      const leadAssignmentUrl = `/v2/storefronts/${storefront_id}/leads/${lead_id}/assign`;
      const leadAsigneeName = `${lead?.assignee?.firstname} ${lead?.assignee?.lastname}`;

      const userTypeChips: UserTypeChip[] =
        tenantTypeToBeCreated === "Buyer"
          ? [{ name: t("Admin"), id: "Buyer Admin" }]
          : [{ name: t("Distributor"), id: "Distributor Admin" }];

      return (
        <PageWrapperEtc>
          <SlideOut
            show={showLeadAssignmentModal}
            closeFlyout={closeLeadAssignmentModal}
          >
            <AssignLeadToUser
              url={leadAssignmentUrl}
              onSuccess={onLeadAssignmentSuccess}
              onError={onLeadAssignmentFailure}
              prefilledUser={lead?.assignee}
            />
          </SlideOut>
          <PageHeader>
            <HeaderLeft>
              <PageTitle>{t("Quote Request")}</PageTitle>
              {lead.number && <IDDesktop>{lead.number}</IDDesktop>}
            </HeaderLeft>
            <HeaderRight>
              <StatusRight color={statusColor} text={statusText} />
            </HeaderRight>
          </PageHeader>
          <HeaderContainer>
            <SubheaderInformationContainer>
              <InnerTitleText>{t("Assigned to: ")}</InnerTitleText>
              <SubheaderContentText>
                {lead.assignee ? (
                  <NameAndIcon>
                    <div>{leadAsigneeName}</div>
                    <div>
                      <EditButton
                        style={{
                          padding: "0",
                          position: "absolute",
                          top: "-7px",
                        }}
                        width={16}
                        height={19}
                        onClick={openLeadAssignmentModal}
                        disabled={isActionDisable}
                      />
                    </div>
                  </NameAndIcon>
                ) : (
                  <ClickableLink
                    style={{ color: theme.primaryButtonBorder }}
                    onClick={openLeadAssignmentModal}
                    disabled={isActionDisable}
                  >
                    {t("Add")}
                  </ClickableLink>
                )}
              </SubheaderContentText>
            </SubheaderInformationContainer>
            {lead.assignment_date && lead.assignee && (
              <SubheaderInformationContainer>
                <InnerTitleText>{t("Assigned on: ")}</InnerTitleText>
                <SubheaderContentText>
                  {formatDateTime(lead.assignment_date)}
                </SubheaderContentText>
              </SubheaderInformationContainer>
            )}
          </HeaderContainer>
          <FullWidthHorizontalSeparator margin={"20px 0 0"} />

          <ContentWrapper style={{ margin: "45px 0 20px 0" }}>
            <Card key="Quote-request-lead-detail">
              <H3>{t("Customer Details")}</H3>
              <Details>
                <div>
                  <InfoBlockExtraSmall
                    header={t("Requested By")}
                    content={
                      <MultiLineBlock>
                        {/* All of the property names are different for every lead.. 
                        Not much point in having 4 different utility functions just to export them and use them once. */}
                        <div>
                          {`${lead.buyer_first_name} ${lead.buyer_last_name}`}
                        </div>
                        <div>{lead.buyer_company_name}</div>
                        <div>
                          {isEmailTooLong ? (
                            <>
                              <span data-tip={lead.buyer_email}>
                                {lead.buyer_email.slice(0, maxEmailLength)}...
                              </span>
                              <ReactTooltip effect="solid" />
                            </>
                          ) : (
                            lead.buyer_email
                          )}
                        </div>
                        <div>
                          <a href={`tel:${lead.buyer_phone_number}`}>
                            {formateInternationalPhoneNumber(
                              lead.buyer_phone_number
                            )}
                          </a>
                        </div>
                      </MultiLineBlock>
                    }
                  />
                </div>
                <div>
                  <InfoBlockExtraSmall
                    header={t("Date Received")}
                    content={formatDateTime(lead.created_at)}
                  />
                  {lead.status === "Added as User" && forwardedToContact && (
                    <div style={{ marginTop: "5px" }}>
                      <InfoBlockExtraSmall
                        header={t("User Added to")}
                        content={forwardedToTenant.name}
                      />
                    </div>
                  )}
                </div>
                {lead.status === "Forwarded" &&
                  forwardedToTenant &&
                  forwardedToContact && (
                    <div>
                      <InfoBlockExtraSmall
                        header={t("Forwarded to")}
                        content={
                          <MultiLineBlock style={{ wordWrap: "break-word" }}>
                            <div>{forwardedToTenant.name}</div>
                            <div>
                              {`${forwardedToContact.first_name} ${forwardedToContact.last_name}`}
                            </div>
                            <EmailContainer>
                              {forwardedToContact.email_address}
                            </EmailContainer>
                            <div>
                              <a
                                href={`tel:${forwardedToContact.phone_number}`}
                              >
                                {formateInternationalPhoneNumber(
                                  forwardedToContact.phone_number
                                )}
                              </a>
                            </div>
                          </MultiLineBlock>
                        }
                      />
                    </div>
                  )}
              </Details>

              {(isCurrentLeadSpam || isGlobalLeadSpam) && (
                <NotificationContainer>
                  <WarningIcon width={18} height={18} />
                  <ActiveWarningMessage>
                    {t(
                      `This lead may be spam. Please review the details carefully before accepting this lead.`
                    )}
                  </ActiveWarningMessage>
                </NotificationContainer>
              )}

              <H3>{t("Quote Request Details")}</H3>
              {hasTransactionData && (
                <WideDetails>
                  <ContactInfoBlockSmall
                    address={lead.address as IAddress}
                    header={t("Ship to")}
                    testid={"address"}
                  />
                  <ContactInfoBlockSmall
                    address={lead!.billing_address}
                    header={t("Bill to")}
                    testid={"billing-address"}
                  />
                  <InfoBlockExtraSmall
                    header={t("Shipment ETA")}
                    content={requestedEta}
                  />
                </WideDetails>
              )}
              {lead.cart.items.map((item: any, index) => (
                <React.Fragment key={item.id + index}>
                  <TransactionItem
                    topLeft={
                      <TransactionItemTopLeft item={item} index={index} />
                    }
                    topRightCol2={
                      <TransactionItemInfoBlock
                        main={`${item.total_volume} ${
                          item.packaging_unit?.name ?? ""
                        }`}
                        lines={
                          !!item.packaging_type?.name
                            ? [item.packaging_type?.name]
                            : undefined
                        }
                        alignRight
                      />
                    }
                    hideSeparatorLine
                  />
                </React.Fragment>
              ))}
              {/*
                  The buttons/actions to offer by user role and status:

                  Producer Seller Admin
                    "New" -> convert, send, reject
                    "Forwarded" -> no buttons
                    "Converted" -> no buttons
                    "Accepted" -> no buttons
                    "Rejected" -> no buttons
                    "Lead Declined"; -> convert, reject (not send)
                */}
              {(roleIsSomeKindOfSeller || userIsDistributorSeller) &&
                (lead.status === "New" || lead.status === "blocked") && (
                  <>
                    {lead.is_existing_customer && (
                      <WarningMessageBox>
                        <WarningIcon width={18} />
                        {t(
                          `This lead may be a user of an existing customer, {{companyName}}. Click Add to Existing Customer to add this lead as a user to {{companyName}}.`,
                          {
                            companyName: lead.company_name,
                          }
                        )}
                      </WarningMessageBox>
                    )}

                    <ButtonSection
                      style={{ marginBottom: "32px", marginTop: "40px" }}
                    >
                      <div>
                        <CancelButton
                          style={{ paddingLeft: "0px" }}
                          onClick={openLeadRejectionModal}
                          disabled={isActionDisable}
                        >
                          {t("Reject This Lead")}
                        </CancelButton>
                        <LeadToBlockModal
                          handleBlockLead={handleBlockLead}
                          blockLeadData={blockLeadData}
                        />
                      </div>
                      <RightButtonContainer>
                        <DropDownButton
                          items={[t("Customer"), t("Distributor")]}
                          textLeft={t("Convert to new")}
                          clickHandler={(item: string) => {
                            handleDropdownClick(item, "convertToNew");
                          }}
                          isDisabled={isActionDisable}
                        />
                        <DropDownButton
                          items={[t("Customer"), t("Distributor")]}
                          textLeft={t("Add to existing")}
                          clickHandler={(item: string) => {
                            handleDropdownClick(item, "addToExisting");
                          }}
                          isDisabled={isActionDisable}
                        />
                      </RightButtonContainer>

                      {showConvertLeadModal && (
                        <BlockLeadToUserModal
                          showConvertLeadModal={showConvertLeadModal}
                          handleCancel={handleCancel}
                          handleConvertToLead={handleConvertToLead}
                          setShowConvertLeadModal={setShowConvertLeadModal}
                        />
                      )}
                    </ButtonSection>

                    <Modal
                      closeModal={closeAddUserToCustomerModal}
                      show={showAddToCustomerModal}
                    >
                      <CreateNewUser
                        tenantId={user.tenant_id}
                        userTypeChips={userTypeChips}
                        defaultUserType={userTypeChips[0].id}
                        leadToUserData={{
                          firstname: lead.buyer_first_name,
                          lastname: lead.buyer_last_name,
                          email_address: lead.buyer_email,
                          phone_number: lead.buyer_phone_number,
                          lead_id: lead.id,
                          existing_customer: {
                            company_name: lead.company_name,
                            customer_id: lead.customer_id,
                          },
                          address: lead.address,
                        }}
                        tenantTypeToFetch={tenantTypeToBeCreated}
                        userFormType="add_to_Existing_customer"
                        onSuccess={() => {
                          closeAddUserToCustomerModal();
                        }}
                        showShippingAddress={true}
                      />
                    </Modal>
                  </>
                )}
              {userIsProducerSeller && lead.status === "Lead Declined" && (
                <ButtonSection style={{ marginTop: "30px" }}>
                  <CancelButton onClick={openLeadRejectionModal}>
                    {t("Reject This Lead")}
                  </CancelButton>
                  <PrimaryButtonLarge onClick={openCreateCustomerModal}>
                    {t("Convert to New Customer")}
                  </PrimaryButtonLarge>
                </ButtonSection>
              )}
              <Modal
                closeModal={closeCreateCustomerModal}
                show={showCreateCustomerModal}
              >
                <CreateNewTenantFormContainer
                  onSuccess={onConvertToCustomer}
                  lead={lead}
                  sellerTenantId={user.tenant_id}
                  type={tenantTypeToBeCreated}
                />
              </Modal>
            </Card>
            <LeadRejectionModal
              show={showLeadRejectionModal}
              closeModal={closeLeadRejectionModal}
              onFormSubmit={handleRejectQuote}
              titleString={t(
                "Are you sure you want to reject this Quote Request?"
              )}
            />
            {lead.rejection_note && (
              <LeadRejectionCard rejection_note={lead.rejection_note} />
            )}
            {lead.internal_notes && (
              <LeadNotes
                internal_notes={lead.internal_notes}
                handleSubmit={onLeadNoteSubmit}
              />
            )}
          </ContentWrapper>
        </PageWrapperEtc>
      );
    }
    // Should never happen, but TypeScript doesn't know that.
    return null;
  }
);
