import type { UserConfigurableSEO } from "./pages/SharedPages/SEO/SEO";
import type {
  FilterType,
  OrderStatusAction,
  ProductDocument,
  SupportedLanguage,
} from "./types/types";

/**
 * A group of functions that return strings for endpoints. For readability,
 * method names are basically "snake_case" where a "_" represents a "/" in the
 * endpoint url. Where a hyphen ("-") occurs in a url segment use camel case.
 * For example, `/my-custom-products/` becomes `_myCustomProducts_`.
 */
export const endpoints = {
  v1_lastPurchase() {
    return `/v1/last-purchase`;
  },
  v1_priceTiers() {
    return `/v1/price-tiers`;
  },
  v1_priceTiers_id(priceTierId: string) {
    return `/v1/price-tiers/${priceTierId}`;
  },
  v1_priceTiers_grouped() {
    return `/v1/price-tiers/grouped`;
  },
  v1_storefronts_id_or_slug_quotes_id_items_id(
    storefrontIdOrSlug: string,
    quoteId: string,
    itemId: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/quotes/${quoteId}/items/${itemId}`;
  },
  v1_storefronts_id_or_slug_quotes_id_items_id_priceTiers(
    storefrontIdOrSlug: string,
    quoteId: string,
    itemId: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/quotes/${quoteId}/items/${itemId}/price-tiers`;
  },
  v1_storefronts_id_customProducts(storefrontIdOrSlug: string) {
    return `/v1/storefronts/${storefrontIdOrSlug}/custom-products`;
  },
  v2_tenants_id_pim_products(tenantIdOrSlug: string) {
    return `/v2/tenants/${tenantIdOrSlug}/pim/products`;
  },
  v2_tenants_id_pim_products_summary(tenantIdOrSlug: string) {
    return `/v2/tenants/${tenantIdOrSlug}/pim/products/summary`;
  },
  v2_storefronts_id_pim_products(storefrontIdOrSlug: string) {
    return `/v2/storefronts/${storefrontIdOrSlug}/pim/products`;
  },
  v2_storefronts_id_pim_assets(tenantIdOrSlug: string) {
    return `/v2/tenants/${tenantIdOrSlug}/pim/assets`;
  },
  v2_storefronts_id_pim_assets_external_links(tenantIdOrSlug: string) {
    return `/v2/tenants/${tenantIdOrSlug}/pim/assets/external-links`;
  },
  v2_storefronts_id_pim_assets_external_links_id(
    tenantIdOrSlug: string,
    linkId?: string
  ) {
    return `/v2/tenants/${tenantIdOrSlug}/pim/assets/external-links/${linkId}`;
  },
  v2_storefronts_id_pim_asset_category() {
    return `/v2/pim/asset-categories`;
  },
  v2_storefronts_id_pim_asset_types() {
    return `/v2/pim/asset-types`;
  },
  v2_storefronts_id_pim_assets_id(tenantIdOrSlug: string, assetId: string) {
    return `/v2/tenants/${tenantIdOrSlug}/pim/assets/${assetId}`;
  },
  v2_storefronts_id_pim_product_cover_image(
    tenantIdOrSlug: string,
    productId: string
  ) {
    return `/v2/tenants/${tenantIdOrSlug}/pim/products/${productId}/cover-image`;
  },
  v2_storefronts_id_pim_templates_summary(tenantIdOrSlug: string) {
    return `/v2/tenants/${tenantIdOrSlug}/pim/templates/summary`;
  },
  v2_tenants_id_pim_assets_id_summary(tenantIdOrSlug: string, assetId: string) {
    return `/v2/tenants/${tenantIdOrSlug}/pim/assets/${assetId}/summary`;
  },
  v2_storefronts_id_pim_actvities(tenantIdOrSlug: string) {
    return `/v2/tenants/${tenantIdOrSlug}/pim/activities`;
  },

  v1_storefronts_id_deliveryTerms(storefrontIdOrSlug: string) {
    return `/v1/storefronts/${storefrontIdOrSlug}/delivery-terms`;
  },
  v1_storefronts_id_deliveryTerms_id(
    storefrontIdOrSlug: string,
    termId: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/delivery-terms/${termId}`;
  },
  v1_storefronts_id_invite(storefrontIdOrSlug: string) {
    return `/v1/storefronts/${storefrontIdOrSlug}/invite`;
  },
  v1_storefronts_id_invite_id_cancel(
    storefrontIdOrSlug: string,
    inviteId: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/invite/${inviteId}/cancel`;
  },
  v1_storefronts_id_invite_id_resend(
    storefrontIdOrSlug: string,
    inviteId: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/invite/${inviteId}/resend`;
  },
  v1_storefronts_id_metadata(storefrontIdOrSlug: string) {
    return `/v1/storefronts/${storefrontIdOrSlug}/metadata`;
  },

  v1_storefronts_id_form_data(
    storefrontIdOrSlug: string,
    language: SupportedLanguage
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/form-data/${language}`;
  },
  /**
   * Language code is like "en" for English, "ja" for Japanese, etc.
   */
  v1_storefronts_id_metadata_languageCode(
    storefrontIdOrSlug: string,
    languageCode: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/metadata/${languageCode}`;
  },
  v1_storefronts_id_packagingTypes(storefrontIdOrSlug: string) {
    return `/v2/storefronts/${storefrontIdOrSlug}/packaging-types`;
  },
  v1_storefronts_id_packagingTypes_id(
    storefrontIdOrSlug: string,
    packagingTypeId: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/packaging-types/${packagingTypeId}`;
  },
  v1_storefronts_id_paymentTerms(storefrontIdOrSlug: string) {
    return `/v1/storefronts/${storefrontIdOrSlug}/payment-terms`;
  },
  v1_storefronts_id_paymentTerms_id(
    storefrontIdOrSlug: string,
    termId: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/payment-terms/${termId}`;
  },
  v1_storefronts_id_paymentModes(storefrontIdOrSlug: string) {
    return `/v1/storefronts/${storefrontIdOrSlug}/payment-modes`;
  },
  v1_storefronts_id_paymentModes_id(
    storefrontIdOrSlug: string,
    termId: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/payment-modes/${termId}`;
  },
  v1_storefronts_id_producedBy(storefrontIdOrSlug: string) {
    return `/v1/storefronts/${storefrontIdOrSlug}/produced-by`;
  },
  v1_storefronts_id_products(storefrontIdOrSlug: string) {
    return `/v1/storefronts/${storefrontIdOrSlug}/products`;
  },
  v1_storefronts_id_products_summary(storefrontIdOrSlug: string) {
    return `/v1/storefronts/${storefrontIdOrSlug}/products/summary`;
  },
  v1_storefronts_id_products_filters(storefrontIdOrSlug: string) {
    return `/v1/storefronts/${storefrontIdOrSlug}/products/filters`;
  },
  v2_storefronts_id_products_filters(storefrontIdOrSlug: string) {
    return `/v2/storefronts/${storefrontIdOrSlug}/products/filters`;
  },
  v1_storefronts_id_products_filters_settings(storefrontIdOrSlug: string) {
    return `/v1/storefronts/${storefrontIdOrSlug}/products/filters/settings`;
  },
  v1_storefronts_id_products_filters_settings_filterType(
    storefrontIdOrSlug: string,
    filterType: FilterType
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/products/filters/settings/${filterType}`;
  },

  v2_storefronts_id_products_filters_customLabels(storefrontIdOrSlug: string) {
    return `/v2/storefronts/${storefrontIdOrSlug}/products/filters/custom-labels`;
  },
  v1_storefronts_id_products_id(
    storefrontIdOrSlug: string,
    productIdOrSlug: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/products/${productIdOrSlug}`;
  },
  v2_tenants_id_pim_products_id(
    storefrontIdOrSlug: string,
    productIdOrSlug: string
  ) {
    return `/v2/tenants/${storefrontIdOrSlug}/pim/products/${productIdOrSlug}`;
  },
  v2_storefronts_id_pim_products_id(
    storefrontIdOrSlug: string,
    productsIdOrSlug: string
  ) {
    return `/v2/storefronts/${storefrontIdOrSlug}/pim/products/${productsIdOrSlug}`;
  },
  v2_tenants_id_pim_products_id_visibility(
    tenantIdOrSlug: string,
    productIdOrSlug: string
  ) {
    return `/v2/tenants/${tenantIdOrSlug}/pim/products/${productIdOrSlug}/product_visibility`;
  },
  v2_tenants_id_pim_storefronts_id_pim_product_visibility(
    tenantIdOrSlug: string,
    storefrontIdOrSlug: string
  ) {
    return `/v2/tenants/${tenantIdOrSlug}/pim/storefronts/${storefrontIdOrSlug}/product_visibility`;
  },

  v1_storefronts_id_products_id_externalIds(
    storefrontIdOrSlug: string,
    productIdOrSlug: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/products/${productIdOrSlug}/external-ids`;
  },
  /**
   * The `group_type` options that can be used for `idOrType` are e.g.
   * "productGroup1", "productGroup2", and "productGroup3".
   */
  v1_storefronts_id_products_id_groups_idOrType(
    storefrontIdOrSlug: string,
    productIdOrSlug: string,
    groupIdOrType: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/products/${productIdOrSlug}/groups/${groupIdOrType}`;
  },
  v1_storefronts_id_products_id_packagingTypes(
    storefrontIdOrSlug: string,
    productIdOrSlug: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/products/${productIdOrSlug}/packaging-types`;
  },
  v1_storefronts_id_products_id_privacy(
    storefrontIdOrSlug: string,
    productIdOrSlug: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/products/${productIdOrSlug}/privacy`;
  },
  v1_storefronts_id_products_id_properties(
    storefrontIdOrSlug: string,
    productIdOrSlug: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/products/${productIdOrSlug}/properties`;
  },
  v1_storefronts_id_products_id_properties_id(
    storefrontIdOrSlug: string,
    productIdOrSlug: string,
    propertyId: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/products/${productIdOrSlug}/properties/${propertyId}`;
  },
  v1_storefronts_id_products_id_specifications(
    storefrontIdOrSlug: string,
    productIdOrSlug: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/products/${productIdOrSlug}/specifications`;
  },
  v1_storefronts_id_products_id_specifications_id(
    storefrontIdOrSlug: string,
    productIdOrSlug: string,
    specificationId: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/products/${productIdOrSlug}/specifications/${specificationId}`;
  },
  v1_storefronts_id_products_id_tags(
    storefrontIdOrSlug: string,
    productIdOrSlug: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/products/${productIdOrSlug}/tags`;
  },
  v1_storefronts_id_products_id_groups_type(
    storefrontIdOrSlug: string,
    productIdOrSlug: string,
    groupType: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/products/${productIdOrSlug}/groups/${groupType}/new`;
  },
  v1_storefronts_id_sampleRequests(storefrontIdOrSlug: string) {
    return `/v1/storefronts/${storefrontIdOrSlug}/sample-requests`;
  },
  v1_storefronts_id_sampleRequests_id(
    storefrontIdOrSlug: string,
    sampleRequestId: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/sample-requests/${sampleRequestId}`;
  },
  v1_storefronts_id_sampleRequests_id_action(
    storefrontIdOrSlug: string,
    sampleRequestId: string,
    action:
      | "accept"
      | "forward"
      | "reject"
      | "cancel"
      | "complete"
      | "ship_sample"
      | "decline"
      | "progress"
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/sample-requests/${sampleRequestId}/${action}`;
  },
  v1_storefronts_id_sampleRequests_id_documents_id(
    storefrontIdOrSlug: string,
    sampleRequestId: string,
    documentId: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/sample-requests/${sampleRequestId}/documents/${documentId}`;
  },
  v1_storefronts_id_sampleRequests_id_items(
    storefrontIdOrSlug: string,
    sampleRequestId: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/sample-requests/${sampleRequestId}/items`;
  },
  v1_storefronts_id_sampleRequests_id_items_id(
    storefrontIdOrSlug: string,
    sampleRequestId: string,
    itemId: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/sample-requests/${sampleRequestId}/items/${itemId}`;
  },
  v1_storefronts_id_sampleRequests_id_shipmentAdvice(
    storefrontIdOrSlug: string,
    sampleRequestId: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/sample-requests/${sampleRequestId}/shipment-advice`;
  },
  v1_storefronts_id_sampleRequests_id_shipmentAdvice_id(
    storefrontIdOrSlug: string,
    sampleRequestId: string,
    shipmentAdviceId?: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/sample-requests/${sampleRequestId}/shipment-advice/${shipmentAdviceId}`;
  },
  v1_storefronts_id_tenant_defaults_deliveryTerms_id(
    storefrontIdOrSlug: string,
    termId: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/tenant/defaults/delivery-terms/${termId}`;
  },
  v1_storefronts_id_tenant_defaults_paymentModes_id(
    storefrontIdOrSlug: string,
    modeId: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/tenant/defaults/payment-modes/${modeId}`;
  },
  v1_storefronts_id_tenant_defaults_paymentTerms_id(
    storefrontIdOrSlug: string,
    termId: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/tenant/defaults/payment-terms/${termId}`;
  },
  v1_storefronts_id_tenants_id(
    storefrontIdOrSlug: string,
    tenantIdOrSlug: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/tenants/${tenantIdOrSlug}`;
  },
  v2_storefronts_id_tenants_id(
    storefrontIdOrSlug: string,
    tenantIdOrSlug: string
  ) {
    return `/v2/tenants/${tenantIdOrSlug}/pim/storefronts/${storefrontIdOrSlug}`;
  },
  v1_storefronts_id_tenants_id_tenants(
    storefrontIdOrSlug: string,
    tenantIdOrSlug: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/tenants/${tenantIdOrSlug}/tenants`;
  },
  v1_storefronts_id_tenants_id_customers_id_settings(
    storefrontIdOrSlug: string,
    sellerIdOrSlug: string,
    buyerIdOrSlug: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/tenants/${sellerIdOrSlug}/customers/${buyerIdOrSlug}/settings`;
  },
  v1_storefronts_id_tenants_id_users(
    storefrontIdOrSlug: string,
    tenantIdOrSlug: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/tenants/${tenantIdOrSlug}/users`;
  },
  v1_storefronts_id_buyers(storefrontIdOrSlug: string) {
    return `/v1/storefronts/${storefrontIdOrSlug}/buyers`;
  },
  v1_storefronts_id_tenants_id_users_id(
    storefrontIdOrSlug: string,
    tenantIdOrSlug: string,
    userId: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/tenants/${tenantIdOrSlug}/users/${userId}`;
  },
  v1_storefronts_id_users_login(storefrontIdOrSlug: string) {
    return `/v1/storefronts/${storefrontIdOrSlug}/users/login`;
  },
  v1_storefronts_id_users_resetPassword_tokens(storefrontIdOrSlug: string) {
    return `/v1/storefronts/${storefrontIdOrSlug}/users/reset-password/tokens`;
  },
  v1_storefronts_id_emailTemplates(storefrontIdOrSlug: string) {
    return `/v1/storefronts/${storefrontIdOrSlug}/email-templates`;
  },
  v1_emailTemplates_key_render_body(template_key: string) {
    return `/v1/email-templates/${template_key}/render/body`;
  },
  v1_storefronts_id_emailTemplates_id(
    storefrontIdOrSlug: string,
    template_id: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/email-templates/${template_id}`;
  },
  v1_storefronts_id_emailTemplates_key_render_body(
    storefrontIdOrSlug: string,
    templateKey: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/email-templates/${templateKey}/render/body`;
  },
  v1_emailTemplates_key(template_key: string) {
    return `/v1/email-templates/${template_key}`;
  },
  v1_storefronts_id_customer_reports_fileType(
    storefrontIdOrSlug: string,
    fileType: "csv" | "tsv" | "json"
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/customers/reports/${fileType}`;
  },
  v1_storefronts_id_distributor_reports_fileType(
    storefrontIdOrSlug: string,
    fileType: "csv" | "tsv" | "json"
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/distributors/reports/${fileType}`;
  },

  v1_storefronts_id_lead_reports_fileType(
    storefrontIdOrSlug: string,
    fileType: "csv" | "tsv" | "json"
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/leads/reports/${fileType}`;
  },
  v1_storefronts_id_sample_reports_fileType(
    storefrontIdOrSlug: string,
    fileType: "csv" | "tsv" | "json"
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/sample-requests/reports/${fileType}`;
  },
  v1_storefronts_id_orders_reports_fileType(
    storefrontIdOrSlug: string,
    fileType: "csv" | "tsv" | "json"
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/orders/reports/${fileType}`;
  },
  v1_storefronts_id_quotes_reports_fileType(
    storefrontIdOrSlug: string,
    fileType: "csv" | "tsv" | "json"
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/quotes/reports/${fileType}`;
  },
  v1_storefronts_id_orders_reports_fields(storefrontIdOrSlug: string) {
    return `/v1/storefronts/${storefrontIdOrSlug}/orders/reports/fields`;
  },
  v1_storefronts_id_quotes_reports_fields(storefrontIdOrSlug: string) {
    return `/v1/storefronts/${storefrontIdOrSlug}/quotes/reports/fields`;
  },
  v1_storefronts_id_sample_reports_fields(storefrontIdOrSlug: string) {
    return `/v1/storefronts/${storefrontIdOrSlug}/sample-requests/reports/fields`;
  },
  v1_storefronts_id_lead_reports_fields(storefrontIdOrSlug: string) {
    return `/v1/storefronts/${storefrontIdOrSlug}/leads/reports/fields`;
  },
  v1_storefronts_id_customer_reports_fields(storefrontIdOrSlug: string) {
    return `/v1/storefronts/${storefrontIdOrSlug}/customers/reports/fields`;
  },
  v1_storefronts_id_distributor_reports_fields(storefrontIdOrSlug: string) {
    return `/v1/storefronts/${storefrontIdOrSlug}/distributors/reports/fields`;
  },
  v1_storefronts_id_orders_id_order_status(
    storefrontIdOrSlug: string,
    orderId: string,
    orderStatusAction: OrderStatusAction
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/orders/${orderId}/${orderStatusAction}`;
  },
  v1_storefronts_id_Notifications(storefrontIdOrSlug: string) {
    return `/v1/storefronts/${storefrontIdOrSlug}/notifications`;
  },
  v1_storefronts_id_or_slug_seo_page(
    storefrontIdOrSlug: string,
    seoPage: UserConfigurableSEO
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/seo/${seoPage}`;
  },
  v1_storefronts_id_or_slug_resources_visibility(storefrontIdOrSlug: string) {
    return `/v1/storefronts/${storefrontIdOrSlug}/resources-visibility`;
  },
  v1_storefronts_id_or_slug_products_id_documents_id(
    storefrontIdOrSlug: string,
    productId: string,
    documentId: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/products/${productId}/documents/${documentId}`;
  },
  v1_storefronts_id_or_slug_products_id_document_id_download(
    storefrontIdOrSlug: string,
    productId: string,
    documentId: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/products/${productId}/document/${documentId}/download`;
  },
  v1_storefronts_id_or_slug_products_id_documents_type(
    storefrontIdOrSlug: string,
    productId: string,
    documentType: ProductDocument["kind"]
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/products/${productId}/documents/${documentType}`;
  },
  v1_products_id_documents_type(
    productId: string,
    documentType: ProductDocument["kind"]
  ) {
    return `/v1/products/${productId}/documents/${documentType}`;
  },
  v1_storefronts_id_or_slug_products_id_links(
    storefrontIdOrSlug: string,
    productId: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/products/${productId}/links`;
  },
  v1_storefronts_id_or_slug_products_id_links_id(
    storefrontIdOrSlug: string,
    productId: string,
    linkId: string
  ) {
    return `/v1/storefronts/${storefrontIdOrSlug}/products/${productId}/links/${linkId}`;
  },

  v1_tenant_id_or_slug_selector_filters(tenantId: string) {
    return `/v1/tenant/${tenantId}/selector/filters`;
  },

  //V2 ENDPOINTS

  v2_pim_types() {
    return `/v2/pim/types`;
  },
  v2_pim_attributes() {
    return `/v2/pim/attributes`;
  },
  v2_tenants_id_pim_attributes_id(tenant_id: string, attribute_id: string) {
    return `/v2/tenants/${tenant_id}/pim/attributes/${attribute_id}`;
  },
  v2_tenants_id_pim_attributes(tenant_id: string) {
    return `/v2/tenants/${tenant_id}/pim/attributes`;
  },

  v2_tenants_id_pim_collections(tenant_id: string) {
    return `/v2/tenants/${tenant_id}/pim/collections`;
  },

  v2_tenants_id_pim_collections_id(tenant_id: string, collectionId: string) {
    return `/v2/tenants/${tenant_id}/pim/collections/${collectionId}`;
  },
  v2_pim_lists() {
    return `/v2/pim/lists`;
  },
  v2_pim_lists_id(list_id: string) {
    return `/v2/pim/lists/${list_id}`;
  },
  v2_tenants_id_pim_get_list_by_name(tenant_id: string, list_name: string) {
    return `/v2/tenants/${tenant_id}/pim/lists-by-name/${list_name}`;
  },
  v2_tenants_id_or_slug_pim_lists_id(tenant_id: string, list_id: string) {
    return `/v2/tenants/${tenant_id}/pim/lists/${list_id}`;
  },
  v2_tenants_id_or_slug_pim_lists(tenant_id: string) {
    return `/v2/tenants/${tenant_id}/pim/lists`;
  },
  v2_tenants_id_or_slug_pim_lists_id_items(tenant_id: string, list_id: string) {
    return `/v2/tenants/${tenant_id}/pim/lists/${list_id}/items`;
  },
  v2_tenants_id_or_slug_pim_lists_id_items_id(
    tenant_id: string,
    list_id: string,
    list_item_id: string
  ) {
    return `/v2/tenants/${tenant_id}/pim/lists/${list_id}/items/${list_item_id}`;
  },
  v2_tenants_id_or_slug_pim_products_id_collections_id(
    tenant_id: string,
    product_id: string,
    collection_id: string
  ) {
    return `/v2/tenants/${tenant_id}/pim/products/${product_id}/collections/${collection_id}`;
  },
  v2_tenants_id_or_slug_pim_products_id_collections_id_rows_id(
    tenant_id: string,
    product_id: string,
    collection_id: string,
    row_id: string
  ) {
    return `/v2/tenants/${tenant_id}/pim/products/${product_id}/collections/${collection_id}/rows/${row_id}`;
  },
  v2_storefronts_id_or_slug_products_id_or_slug_status(
    storefront_id: string,
    product_id: string
  ) {
    return `/v2/storefronts/${storefront_id}/products/${product_id}/status`;
  },
  v2_tenants_id_products_id_privacy(
    tenant_id: string,
    product_id_or_slug: string
  ) {
    return `/v2/tenants/${tenant_id}/products/${product_id_or_slug}/privacy`;
  },
  v2_storefronts_id_tenants_id_tenants(
    storefrontIdOrSlug: string,
    tenantIdOrSlug: string
  ) {
    return `/v2/storefronts/${storefrontIdOrSlug}/tenants/${tenantIdOrSlug}/tenants`;
  },
  v2_storefronts_id_tenants_id_tenants_basic(
    storefrontIdOrSlug: string,
    tenantIdOrSlug: string
  ) {
    return `/v2/storefronts/${storefrontIdOrSlug}/tenants/${tenantIdOrSlug}/tenants-basic`;
  },
  v2_tenants_id_or_slug_pim_templates_id_groups_id_attributes_restricted(
    tenant_id: string,
    template_id: string,
    group_id: string
  ) {
    return `/v2/tenants/${tenant_id}/pim/templates/${template_id}/groups/${group_id}/attributes/restricted`;
  },
  v2_tenants_id_or_slug_pim_templates_id_collections_id_attributes_restricted(
    tenant_id: string,
    template_id: string,
    collection_id: string
  ) {
    return `/v2/tenants/${tenant_id}/pim/templates/${template_id}/collections/${collection_id}/attributes/restricted`;
  },
  v1_storefronts_id_or_slug_reindex(storefrontIdOrSlug: string) {
    return `/v1/storefronts/${storefrontIdOrSlug}/reindex`;
  },
  v2_storefronts_id_or_slug_pim_products_id_or_slug_allowCustomSKU(
    storefrontId: string,
    productId: string
  ) {
    return `/v2/storefronts/${storefrontId}/pim/products/${productId}/allow_custom_sku`;
  },
  v2_tenants_tenant_id_or_slug_pim_attributes_portfolio(tenant_id: string) {
    return `/v2/tenants/${tenant_id}/pim/attributes/portfolio`;
  },
  v2_tenants_id_pim_assets_categories(tenant_id: string) {
    return `/v2/tenants/${tenant_id}/pim/assets/categories`;
  },
  v1_all_languages() {
    return `/v1/languages`;
  },

  v1_storefronts_id_languages(storefrontId: string) {
    return `/v1/storefronts/${storefrontId}/languages`;
  },
  v2_storefronts_id_dashboard_summary(storefrontId: string) {
    return `/v2/storefronts/${storefrontId}/dashboard/summary`;
  },
  v2_storefronts_id_dashboard_product_updates(storefrontId: string) {
    return `/v2/storefronts/${storefrontId}/dashboard/product-updates`;
  },
  v2_storefronts_id_dashboard_other_updates(storefrontId: string) {
    return `/v2/storefronts/${storefrontId}/dashboard/other-updates`;
  },
  v2_storefronts_id_product_selectors(storefrontId: string) {
    return `v2/storefronts/${storefrontId}/selectors`;
  },
  v2_tenants_tenant_id_product_uploads(tenantIdOrSlug: string) {
    return `/v2/tenants/${tenantIdOrSlug}/product-uploads`;
  },
  v2_tenants_tenant_id_product_uploads_upload_id_notifications(
    tenantIdOrSlug: string,
    uploadId: string
  ) {
    return `/v2/tenants/${tenantIdOrSlug}/product-uploads/${uploadId}/notifications`;
  },
  v2_tenants_tenant_id_templates_template_id_download(
    tenantIdOrSlug: string,
    templateId: string
  ) {
    return `/v2/tenants/${tenantIdOrSlug}/templates/${templateId}/download`;
  },
  v2_storefronts_id_pim_products_export_async(storefront_id: string) {
    return `/v2/storefronts/${storefront_id}/pim/products/export/async`;
  },
  v2_storefronts_id_pim_products_export_job_id(
    storefront_id: string,
    job_id: string
  ) {
    return `/v2/storefronts/${storefront_id}/pim/products/export/${job_id}`;
  },
  v2_tenants_tenant_id_templates_template_id_upload(
    tenantIdOrSlug: string,
    templateId: string
  ) {
    return `/v2/tenants/${tenantIdOrSlug}/templates/${templateId}/upload`;
  },
  v2_tenants_tenant_id_pim_products_upload(tenant_id: string) {
    return `/v2/tenants/${tenant_id}/pim/products/upload`;
  },
  v2_tenants_tenant_id_templates_template_id_generated_assets(
    tenantIdOrSlug: string,
    templateId: string
  ) {
    return `/v2/tenants/${tenantIdOrSlug}/templates/${templateId}/generated_assets`;
  },
  v2_tenants_tenant_id_templates_template_id_generated_assets_id(
    tenantIdOrSlug: string,
    templateId: string,
    assetId: string
  ) {
    return `/v2/tenants/${tenantIdOrSlug}/templates/${templateId}/generated_assets/${assetId}`;
  },
  v2_tenants_tenant_id_templates_template_id_generated_assets_id_images(
    tenantIdOrSlug: string,
    templateId: string,
    assetId: string
  ) {
    return `/v2/tenants/${tenantIdOrSlug}/templates/${templateId}/generated_assets/${assetId}/images`;
  },
  v2_tenants_tenant_id_products_product_id_generate_assets_id_async(
    tenantIdOrSlug: string,
    productId: string,
    assetId: string
  ) {
    return `/v2/tenants/${tenantIdOrSlug}/products/${productId}/generate-assets/${assetId}/async`;
  },
  v2_tenants_tenant_id_products_product_id_generate_assets_id_status(
    tenantIdOrSlug: string,
    productId: string,
    assetId: string
  ) {
    return `/v2/tenants/${tenantIdOrSlug}/products/${productId}/generate-assets/${assetId}/status`;
  },
  v2_tenants_tenant_id_pim_products_status(tenantId: string) {
    return `/v2/tenants/${tenantId}/pim/products/status`;
  },
  v2_tenants_tenant_id_pim_products_status_notifications_notification_id(
    tenant_id: string,
    notification_id: string
  ) {
    return `/v2/tenants/${tenant_id}/pim/products/status/notifications/${notification_id}`;
  },

  v2_tenants_tenant_id_pim_assets_uploads(tenantIdOrSlug: string) {
    return `/v2/tenants/${tenantIdOrSlug}/pim/assets/uploads`;
  },
  v2_tenants_tenant_id_pim_assets_uploads_upload_id(
    tenantIdOrSlug: string,
    uploadId: string
  ) {
    return `/v2/tenants/${tenantIdOrSlug}/pim/assets/uploads/${uploadId}`;
  },
  v2_tenants_tenant_id_pim_assets_uploads_upload_id_submit(
    tenantIdOrSlug: string,
    uploadId: string
  ) {
    return `/v2/tenants/${tenantIdOrSlug}/pim/assets/uploads/${uploadId}/submit`;
  },
  v2_tenants_tenant_id_pim_assets_uploads_download: (tenant_id: string) =>
    `/v2/tenants/${tenant_id}/pim/assets/uploads/download`,
  v2_tenants_tenant_id_pim_assets_associate: (tenant_id: string) =>
    `/v2/tenants/${tenant_id}/pim/assets/associate`,
  v2_tenants_tenant_id_pim_products_connections(tenant_id: string) {
    return `/v2/tenants/${tenant_id}/pim/products/connections`;
  },
  v2_tenants_tenant_id_pim_products_access(tenant_id: string) {
    return `/v2/tenants/${tenant_id}/pim/products/access`;
  },
  v2_tenants_tenant_id_pim_attribute_id_translations(
    tenant_id_or_slug: string,
    attribute: "attributes" | "groups" | "collections",
    id: string
  ) {
    return `/v2/tenants/${tenant_id_or_slug}/pim/${attribute}/${id}/translations`;
  },
  v2_storefronts_id_search_display_attributes(storefront_id: string) {
    return `/v2/storefronts/${storefront_id}/search-display-attributes`;
  },
  v2_tenants_tenant_id_pim_products_attributes_summary(tenant_id: string) {
    return `v2/tenants/${tenant_id}/pim/products/attributes-summary`;
  },
  v2_tenants_tenant_id_pim_products_document_search(tenant_id: string) {
    return `/v2/tenants/${tenant_id}/pim/products/document-search`;
  },
  v2_tenants_tenant_id_products_product_id_list_prices(
    tenant_id: string,
    product_id: string
  ) {
    return `/v2/tenants/${tenant_id}/products/${product_id}/list-prices`;
  },
  v2_tenants_tenant_id_products_product_id_sku_sku_id_list_prices(
    tenant_id: string,
    product_id: string,
    sku_number: string
  ) {
    return `/v2/tenants/${tenant_id}/products/${product_id}/skus/${sku_number}/list-prices`;
  },
  v2_tenants_tenant_id_products_product_id_sku_sku_id_list_prices_list_prices_id(
    tenant_id: string,
    product_id: string,
    sku_number: string,
    list_price_number: string
  ) {
    return `/v2/tenants/${tenant_id}/products/${product_id}/skus/${sku_number}/list-prices/${list_price_number}`;
  },
  v2_storefronts_storefront_id_log_events(storefront_id: string) {
    return `/v2/storefronts/${storefront_id}/log-events`;
  },
  v2_storefronts_storefront_id_fees(storefront_id: string) {
    return `/v2/storefronts/${storefront_id}/fees`;
  },
  v2_storefronts_storefront_id_fees_id(
    storefront_id: string,
    fees_and_charges_id: string
  ) {
    return `/v2/storefronts/${storefront_id}/fees/${fees_and_charges_id}`;
  },
};
