import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { DropDown } from "../../../../../components/DropDown/DropDown";
import { SoftHeader2 } from "../../../../../components/Typography/Typography";
import type { DataMutate } from "../../../../../types/types";
import type { PIMProductBasePaginagedOutput } from "../../../../../types/types.PIM";
import { AccessUpdate } from "./AccessUpdate";
import { ProductStatusUpdate } from "./ProductStatusUpdate";
import { StorefrontConnectionUpdate } from "./StorefrontConnectionUpdate";

const BulkActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  .place_above {
    z-index: 1;
  }
`;

type BulkProductsType = {
  id: string;
  name: string;
};

export const BulkActionOnProducts = ({
  rows,
  mutateProducts,
  onResetSelectedRows,
}: {
  rows: BulkProductsType[] | Promise<BulkProductsType[]>;
  mutateProducts: DataMutate<PIMProductBasePaginagedOutput>;
  onResetSelectedRows: () => void;
}) => {
  const { t } = useTranslation();

  const [selectedAction, setSelectedAction] = useState<string>();

  const [row_ids, set_row_ids] = useState<string[]>([]);

  const bulkActions = useMemo(
    () => [
      t("Change Status"),
      t("Activate/Deactivate on storefront"),
      t("Manage access"),
    ],
    [t]
  );

  const selectBulkAction = (action: string) => {
    setSelectedAction(action);
  };

  const resetBulkAction = () => setSelectedAction(undefined);

  const onCompleteAction = () => {
    resetBulkAction();
    mutateProducts();
    onResetSelectedRows();
  };

  useEffect(() => {
    if (rows instanceof Promise) {
      rows.then((data) => {
        set_row_ids(data.map((row) => row.id));
      });
    } else {
      set_row_ids(rows.map((row) => row.id));
    }
  }, [rows]);

  return (
    <BulkActionWrapper>
      <SoftHeader2>{t("Bulk Actions:")}</SoftHeader2>
      <DropDown
        items={bulkActions}
        activeItem={selectedAction ?? ""}
        showIcon
        hideBorder={false}
        textLeft={t("Manage") + ":"}
        direction={"left"}
        clickHandler={selectBulkAction}
        className="place_above"
      />
      <ProductStatusUpdate
        show={selectedAction === bulkActions[0]}
        onCompleteUpdate={onCompleteAction}
        resetBulkAction={resetBulkAction}
        product_ids={row_ids}
        onCancelUpdate={resetBulkAction}
      />
      <StorefrontConnectionUpdate
        show={selectedAction === bulkActions[1]}
        onComplete={onCompleteAction}
        onCancel={resetBulkAction}
        product_ids={row_ids}
      />
      <AccessUpdate
        show={selectedAction === bulkActions[2]}
        product_ids={row_ids}
        onComplete={onCompleteAction}
        onCancel={resetBulkAction}
      />
    </BulkActionWrapper>
  );
};
