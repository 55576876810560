import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import {
  defaultHandleSort,
  updateCustomLabels,
  useStoreState,
  useSupportedLanguages,
} from "../../../../../../util/util";
import useSWR from "swr";
import type {
  OptionType,
  TagClassificationConfig,
} from "../../../../../../types/types";
import { endpoints } from "../../../../../../endpoints";
import { ArrayParam, StringParam, useQueryParams } from "use-query-params";
import { useEffect, useMemo, useRef, useState } from "react";
import type { Row, SortingRule } from "react-table";
import { useHistory, useLocation } from "react-router-dom";
import { groupPortfolioParamsByKey } from "../../../../../public";
import { useDebounce } from "../../../../../../util/hooks";
import type { ConstructQuery } from "../../ProductsList.util";
import { applyFiltersToURL } from "../../ProductsList.util";
import type {
  PIMProductBase,
  PIMProductBasePaginagedOutput,
} from "../../../../../../types/types.PIM";
import { ContentWrapper } from "../../../../../../layout/portalPageLayout";
import { SearchBar } from "../../../../../../components/SearchBar/SearchBar";
import { FiltersArea } from "../../../../../../components/Filters/Filters";
import { DropdownContainer } from "../../../../../../components/Layout/Layout";
import { DropDown } from "../../../../../../components/DropDown/DropDown";
import { Table } from "../../../../../../components/Table/Table";
import { Pagination } from "../../../../../../components/Pagination/Pagination";
import { TextButton } from "../../../../../../components/Buttons/Buttons";
import { SoftHeader2 } from "../../../../../../components/Typography/Typography";
import type {
  AsyncTableType,
  UploadState,
} from "./createProductFromUploads.util";
import type { TableRefProps } from "../../../../../../components/Table/utils";

export const TotalProducts = styled.div`
  color: ${({ theme }) => theme.secondaryTextColor};
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin-bottom: 0;
`;

const TotalCountContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

/**
 * Products list page (e.g. manage > products).  Used for seller admin.
 */
export function CreateProductsList({
  handleSelectRows,
  selectedTemplate,
  defaultSelectedRows,
}: {
  handleSelectRows: (rows: UploadState["selected_products"]) => void;
  selectedTemplate: OptionType<string>;
  defaultSelectedRows: UploadState["selected_products"];
}) {
  const queryObject = () => {
    const querylist: any = {
      template: StringParam,
      q: StringParam,
      status: ArrayParam,
    };

    return querylist;
  };

  const [query, setQuery] = useQueryParams(queryObject());

  const [sortingRules, setSortingRules] = useState<{
    sortBy?: string;
    orderBy: "asc" | "desc";
  }>({
    orderBy: "asc",
  });
  const [filtersFromUrl, setFiltersFromUrl] =
    useState<{ [key: string]: string[] }>();
  const [tableData, setTableData] = useState<AsyncTableType[]>([]);
  // searchQuery is loaded from the URL if q exists.
  const [searchQuery, setSearchQuery] = useState(query.q || "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const [offset, setOffset] = useState(0);
  const perPageItems = [10, 20, 50];
  const [perPage, setPerPage] = useState(10);
  const [tablePagination, setTablePagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
    totalCount: 0,
  });
  const [selected_rows_count, set_selected_rows_count] = useState<number>();
  const [default_rows, set_default_rows] =
    useState<{ [pageNumber: string]: Row<AsyncTableType>[] } | "all">();

  const { t } = useTranslation();
  const { supportedLanguageOptions } = useSupportedLanguages();
  const { storefront_id, tenant_id } = useStoreState();
  const { search, pathname } = useLocation();
  const history = useHistory();
  const resetSelectedRowsRef = useRef<TableRefProps>(null);

  const { data: customLabels, error: customLabelsError } = useSWR<
    TagClassificationConfig[]
  >(
    storefront_id
      ? endpoints.v2_storefronts_id_products_filters_customLabels(storefront_id)
      : null,
    { revalidateOnFocus: true }
  );

  updateCustomLabels(customLabels, supportedLanguageOptions);

  const handleSort = async (rules: SortingRule<object>[]) =>
    defaultHandleSort(rules, sortingRules, setSortingRules, setTableData);

  const changePerPage = (perPage: number) => {
    setPerPage(perPage);
    if (perPage > offset) {
      setOffset(0);
    }
  };

  const statuses = useMemo(
    () => ["published", "unpublished", "draft", "staged", "unpublished_staged"],
    []
  );

  /**
   * construct query from selected filters,
   * add search term if it exists
   */
  function constructQuery({
    baseUrl,
    query,
    filtersFromUrl,
  }: ConstructQuery<PIMProductBase>) {
    const paramsWithOffset = new URLSearchParams(
      `offset=${offset}&limit=${perPage}&template=${selectedTemplate.label}`
    );

    statuses.forEach((status) => {
      paramsWithOffset.append("status", status);
    });

    if (query) paramsWithOffset.append("q", query);
    paramsWithOffset.append("order_by", sortingRules.orderBy || "asc");
    if (sortingRules.sortBy) {
      paramsWithOffset.append("sort_by", sortingRules.sortBy);
    }
    paramsWithOffset.append("show_inactive", "true");
    return (
      baseUrl +
      "?" +
      applyFiltersToURL({
        filtersFromUrl,
        params: paramsWithOffset,
      })
    );
  }

  const {
    data: productsResponse,
    error: productsError,
    mutate: mutateProducts,
  } = useSWR<PIMProductBasePaginagedOutput>(
    constructQuery({
      baseUrl: endpoints.v2_tenants_id_pim_products_summary(tenant_id),
      query: debouncedSearchQuery,
      filtersFromUrl,
    })
  );

  const isLoading = !productsResponse && !productsError;

  const tableColumns = useMemo(
    () => [
      {
        Header: t("Product ID"),
        accessor: "externalId",
        disableSortBy: true,
      },
      {
        Header: t("Product Name"),
        accessor: "name",
      },
      {
        Header: t("Chemical Name"),
        accessor: "chemical_name",
      },
    ],
    [t]
  );

  const handleClearFilter = () => {
    const destination = pathname;
    history.replace(destination);
    setQuery({
      q: query.q,
      template: selectedTemplate.label,
      status: statuses,
    });
    mutateProducts();
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOffset(0);
    setSearchQuery(e.target.value);
  };
  const handleClearSearch = () => {
    setSearchQuery("");
    setQuery({ q: undefined });
    setOffset(0);
  };

  const changePage = (offset: number) => {
    setOffset(offset);
    setTableData([]);
  };

  const onSelectRows = async (
    rows:
      | {
          [pageNumber: string]: Row<AsyncTableType>[];
        }
      | "all"
  ) => {
    set_selected_rows_count(
      rows === "all"
        ? tablePagination.totalCount
        : Object.values(rows).flat().length
    );
    handleSelectRows(rows);
  };

  const onSelectAllItems = () => {
    resetSelectedRowsRef.current?.selectAllItems();
    set_selected_rows_count(tablePagination.totalCount);
  };

  const onResetRows = () => {
    resetSelectedRowsRef.current?.resetSelectedRows();
    set_default_rows({});
  };

  useEffect(() => {
    const params = new URLSearchParams(search.substring(1));
    const filters = groupPortfolioParamsByKey(params);
    setFiltersFromUrl(filters);
  }, [search]);

  useEffect(() => {
    const handleProductsData = ({
      data,
      pagination,
    }: PIMProductBasePaginagedOutput) => {
      const tableData = data.map(
        ({
          id,
          name,
          primary_staged_product_id,
          product_number,
          chemical_name,
        }) => ({
          id: product_number ?? primary_staged_product_id ?? id,
          externalId: product_number ?? "--",
          name: name ?? "--",
          chemical_name: chemical_name ?? "--",
        })
      );
      let is_data_changed = false;
      setTableData((prev) => {
        is_data_changed =
          prev[0]?.id !== tableData[0]?.id ||
          prev[prev.length - 1]?.id !== tableData[tableData.length - 1]?.id ||
          prev[Math.floor(prev.length / 2) - 1]?.id !==
            tableData[Math.floor(tableData.length / 2) - 1]?.id;
        return tableData;
      });
      setTablePagination((prev) => {
        const cur = {
          perPage: perPage,
          pageCount: Math.ceil(pagination.total / perPage),
          pageIndex: pagination.offset / perPage + 1,
          totalCount: pagination.total,
        };
        if (is_data_changed && prev.pageIndex === cur.pageIndex) {
          onResetRows();
        }
        return cur;
      });
    };

    if (productsResponse) {
      const { data: products, pagination } = productsResponse;
      handleProductsData({ data: products, pagination });
    }
  }, [productsResponse, perPage]);

  useEffect(() => {
    setQuery({
      template: selectedTemplate.label,
      status: statuses,
    });
  }, [selectedTemplate.label, setQuery, statuses]);

  useEffect(() => {
    // This useEffect handles setting the selected product,
    // and keeping the selected product and query in sync with the URL.
    if (debouncedSearchQuery === "") setQuery({ q: undefined });
    if (debouncedSearchQuery) {
      // update the URL every time the debounced query changes
      setQuery({ q: debouncedSearchQuery });
    }
  }, [setQuery, debouncedSearchQuery]);

  useEffect(() => {
    if (search) {
      mutateProducts();
    }
  }, [search, mutateProducts]);

  useEffect(() => {
    if (defaultSelectedRows) {
      set_default_rows(
        Array.isArray(defaultSelectedRows) ? "all" : defaultSelectedRows
      );
      set_default_rows(defaultSelectedRows);
      set_selected_rows_count(
        defaultSelectedRows === "all"
          ? tablePagination.totalCount
          : Object.values(defaultSelectedRows).flat().length
      );
    }
  }, [defaultSelectedRows, tablePagination.totalCount]);

  return (
    <>
      <div style={{ marginTop: "30px" }}>
        <SearchBar
          query={searchQuery}
          placeHolder={t("Search by product name or CAS#")}
          handleChange={handleSearch}
          handleClearInput={handleClearSearch}
          maxWidth="450px"
        />
      </div>
      {!customLabelsError && (
        <FiltersArea
          resetOffset={setOffset}
          customLabels={customLabels ?? []}
          clearFilter={() => handleClearFilter()}
        />
      )}
      <DropdownContainer style={{ position: "relative", zIndex: 2 }}>
        <DropDown
          items={perPageItems}
          activeItem={perPage}
          textLeft={t("items") + ":"}
          textRight={t(" Per Page")}
          direction={"right"}
          className={"per_Page"}
          clickHandler={changePerPage}
        />
        {selected_rows_count && selected_rows_count > 0 ? (
          <TotalCountContainer>
            <TotalProducts>
              {selected_rows_count} {t("selected")}
            </TotalProducts>
            <TextButton onClick={onResetRows}>
              <SoftHeader2>{t("Unselect All")}</SoftHeader2>
            </TextButton>
            {selected_rows_count < tablePagination.totalCount && (
              <TextButton onClick={onSelectAllItems}>
                <SoftHeader2>
                  {t("Select all {{items}}", {
                    items: tablePagination.totalCount,
                  })}
                </SoftHeader2>
              </TextButton>
            )}
          </TotalCountContainer>
        ) : (
          <TotalProducts>
            {tablePagination.totalCount} {t("products")}
          </TotalProducts>
        )}
      </DropdownContainer>
      <ContentWrapper style={{ position: "relative", zIndex: 1 }}>
        <Table
          columns={tableColumns}
          data={tableData}
          isLoading={isLoading}
          error={productsError}
          handleSort={handleSort}
          handleSelectRows={onSelectRows}
          defaultSelectedRows={default_rows}
          pageIndex={tablePagination.pageIndex}
          ref={resetSelectedRowsRef}
        />
        <Pagination
          pagination={tablePagination}
          offset={offset}
          handlePageClick={changePage}
        />
      </ContentWrapper>
    </>
  );
}
