import { useTranslation } from "react-i18next";
import type { DataMutate } from "../../../../types/types";
import type { CollectionSchema, PIMProduct } from "../../../../types/types.PIM";
import {
  toTitleCase,
  useStoreState,
  useUpdateProductStatus,
} from "../../../../util/util";
import {
  addRow,
  deleteRow,
  editCollectionRow,
  GENERATED_UUID,
} from "../../../admin/SellerAdmin/PIM/components/ProductCollectionSchemaTable/ProductCollectionSchema.util";
import { ProductCollectionSchemaTable } from "../../../admin/SellerAdmin/PIM/components/ProductCollectionSchemaTable/ProductCollectionSchemaTable";
import type { OnSubmitValue } from "../../../admin/SellerAdmin/PIM/components/ProductCollectionSchemaTable/type";
import { CustomSKUConfig } from "./CustomSKUConfig";

export const CollectionComponent = ({
  collection,
  product,
  fetchProductData,
  isPortfolio = false,
  replaceProductId,
}: {
  collection: CollectionSchema;
  product: PIMProduct;
  fetchProductData: DataMutate<PIMProduct>;
  replaceProductId: (id: string) => void;
  isPortfolio?: boolean;
}) => {
  const { tenant_id } = useStoreState();
  const { t } = useTranslation();
  const updateProductStatus = useUpdateProductStatus({ product });

  const getUpdatedProductRowId = (
    rowId: string,
    updatedProduct: PIMProduct
  ) => {
    const updatedProductCollection =
      updatedProduct.product_schema.collections.find(
        ({ id }) => collection.id === id
      );
    const collectionNotFoundError = {
      response: {
        data: {
          message: t("Collection not found"),
        },
      },
    };
    if (!updatedProductCollection) throw collectionNotFoundError;
    const rowIdx = collection.value_rows!.findIndex(
      ({ row_id }) => row_id === rowId
    );
    const rowNotFoundError = {
      response: {
        data: {
          message: t("Could not find row id"),
        },
      },
    };
    if (rowIdx === -1) throw rowNotFoundError;
    return updatedProductCollection.value_rows![rowIdx].row_id;
  };

  const onEditRow = async (values: OnSubmitValue) => {
    const { row_id, ...formValue } = values;

    try {
      const { data: updatedProduct } = await updateProductStatus();
      const { data } = await editCollectionRow({
        values: {
          row_id: getUpdatedProductRowId(row_id, updatedProduct),
          ...formValue,
        },
        tenant_id,
        product_id: updatedProduct.id,
        collection_id: collection.id,
      });
      if (
        data.display_status === "staged" ||
        data.display_status === "unpublished_staged"
      ) {
        const product_id =
          data.product_number ?? data.primary_staged_product_id ?? data.id;
        replaceProductId(product_id);
      } else {
        fetchProductData();
      }
    } catch (error) {
      throw error;
    }
  };

  const onAddRow = async (values: OnSubmitValue) => {
    try {
      const { data: updatedProduct } = await updateProductStatus();
      const { data } = await addRow({
        values,
        tenant_id,
        product_id: updatedProduct.id,
        collection_id: collection.id,
      });
      if (
        data.display_status === "staged" ||
        data.display_status === "unpublished_staged"
      ) {
        const product_id =
          data.product_number ?? data.primary_staged_product_id ?? data.id;
        replaceProductId(product_id);
      } else {
        fetchProductData();
      }
    } catch (error) {
      throw error;
    }
  };

  const onDeleteRow = async (row_id: string) => {
    try {
      const { data: updatedProduct } = await updateProductStatus();
      await deleteRow({
        tenant_id,
        collection_id: collection.id,
        product_id: updatedProduct.id,
        row_id: getUpdatedProductRowId(row_id, updatedProduct),
      });
      if (
        updatedProduct.display_status === "staged" ||
        updatedProduct.display_status === "unpublished_staged"
      ) {
        const product_id =
          updatedProduct.product_number ??
          updatedProduct.primary_staged_product_id ??
          updatedProduct.id;
        replaceProductId(product_id);
      } else {
        await fetchProductData();
      }
    } catch (error) {
      throw error;
    }
  };

  const onRowSubmit = async (values: OnSubmitValue) => {
    if (values.row_id.includes(GENERATED_UUID)) {
      return onAddRow(values);
    } else {
      return onEditRow(values);
    }
  };

  const isSKUCollection = collection.object_type === "skus";
  const canShowSKUConfig = isSKUCollection && !isPortfolio;

  return (
    <>
      <ProductCollectionSchemaTable
        collectionSchema={collection}
        title={toTitleCase(
          collection.display_name
            ? t([collection.display_name])
            : t(collection.name)
        )}
        onSubmitForm={onRowSubmit}
        onDeleteRow={onDeleteRow}
        isPortfolio={isPortfolio}
        productStatus={product.status}
        isEditable={product.is_editable}
      />
      {canShowSKUConfig && (
        <CustomSKUConfig
          allow_custom_sku={product.allow_custom_sku}
          replaceProductId={replaceProductId}
          updateProductStatus={updateProductStatus}
          fetchProductData={fetchProductData}
          productIsEditable={
            product.is_editable && product.status !== "archived"
          }
        />
      )}
    </>
  );
};
