import { useTranslation } from "react-i18next";
import { H3, H5 } from "../../../../components/Typography/Typography";
import type {
  PIMProduct,
  ProductListPriceSchema,
  ProductListPriceUpdateSchema,
} from "../../../../types/types.PIM";
import { Form } from "../../../../layout/FormLayout";
import { convertProductSKUToOption } from "../../../../util/util";
import type { OptionType, ProductSKU } from "../../../../types/types";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { SelectBoxV2 } from "../../../../components/SelectBoxV2/SelectBoxV2";
import { DatePicker } from "../../../../components/DatePicker/DatePicker";
import styled from "styled-components/macro";
import { TextField } from "../../../../components/TextFields/TextFields";
import { strings } from "../../../../util/strings";
import {
  DeleteButton,
  PrimaryButtonFitContainer,
  SecondaryButtonMedium,
} from "../../../../components/Buttons/Buttons";
import { positiveIntegerRegex } from "../../../../util/regexes";
import { useState } from "react";
import type { OnCompleteArgs } from "./ProductPricingTab";

const ListPriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

type FormValue = {
  sku: OptionType<ProductSKU>;
  valid_until: string;
  first_list_price: {
    quantity: number;
    price: number;
  };
  list_prices?: {
    quantity: number;
    price: number;
  }[];
};

export const AddOrEditProductSkuListPrice = ({
  list_price,
  on_complete,
  product,
}: {
  list_price?: ProductListPriceSchema;
  on_complete: (on_complete_data: OnCompleteArgs) => Promise<void>;
  product: PIMProduct;
}) => {
  const [is_submitting, set_is_submitting] = useState(false);
  const { t } = useTranslation();
  const methodsOfUseForm = useForm();
  const { control, errors, formState, handleSubmit, register } =
    methodsOfUseForm;

  const { fields, remove, append } = useFieldArray({
    control,
    name: "list_prices",
  });

  const on_submit = async (values: FormValue) => {
    set_is_submitting(true);
    const { sku, valid_until, first_list_price, list_prices } = values;
    const list_price_data: ProductListPriceUpdateSchema = {
      valid_until: valid_until,
      minimum_units: first_list_price.quantity,
      price: String(first_list_price.price),
      currency: list_price?.currency ?? "USD",
    };
    const other_list_prices: ProductListPriceUpdateSchema[] = (
      list_prices ?? []
    ).map((item) => ({
      sku_id: sku.value.id,
      valid_until: valid_until,
      minimum_units: item.quantity,
      price: String(item.price),
      currency: list_price?.currency ?? "USD",
    }));
    const patch_data = !!list_price ? list_price_data : undefined;
    const post_data = !Boolean(list_price)
      ? [list_price_data, ...other_list_prices]
      : other_list_prices;
    await on_complete({
      list_price_number: list_price?.number,
      sku_number: sku.value.number,
      patch_data,
      post_data,
    });
    set_is_submitting(false);
  };

  const productSkuOptions = product.product_skus.reduce<
    OptionType<ProductSKU>[]
  >(
    (acc, sku) =>
      sku.is_sample ? acc : [...acc, convertProductSKUToOption(sku)],
    []
  );

  return (
    <>
      <H3>{list_price ? t("Edit List Price") : t("Add List Price")}</H3>
      <Form onSubmit={handleSubmit(on_submit)}>
        <Controller
          as={SelectBoxV2}
          control={control}
          name="sku"
          placeholder={t("SKU")}
          options={productSkuOptions}
          rules={{
            required: true,
            validate: (val) => val.value || strings(t).thisIsARequiredField,
          }}
          errors={errors}
          formState={formState}
          defaultValue={
            productSkuOptions.find(
              (option) => option.value.id === list_price?.sku?.id
            ) ?? { label: undefined, value: undefined }
          }
        />
        <Controller
          name={"valid_until"}
          control={control}
          defaultValue={list_price?.valid_until ?? null}
          rules={{ required: strings(t).thisIsARequiredField }}
          render={() => (
            <DatePicker
              label={t("Valid Until")}
              name={"valid_until"}
              methodsOfUseForm={methodsOfUseForm}
              required={true}
              defaultValue={list_price?.valid_until ?? null}
            />
          )}
        />
        <H5>{t("List Price")}</H5>
        <ListPriceWrapper>
          <div style={{ flex: 1 }}>
            <TextField
              name={`first_list_price.quantity`}
              label={t("Minimum Units")}
              autoComplete={"quantity"}
              theref={register({
                required: strings(t).thisIsARequiredField,
                pattern: {
                  value: positiveIntegerRegex,
                  message: t("Must be a valid numeric value"),
                },
              })}
              defaultValue={list_price?.minimum_units}
              formState={formState}
              errors={{
                "first_list_price.quantity":
                  errors?.first_list_price?.quantity ?? undefined,
              }}
              type="number"
            />
          </div>
          <div style={{ flex: 1 }}>
            <TextField
              name={`first_list_price.price`}
              label={t("Price ($/UoM)")}
              autoComplete={"price"}
              theref={register({
                required: strings(t).thisIsARequiredField,
                min: {
                  value: 0,
                  message: t("Price must be greater than 0"),
                },
              })}
              defaultValue={list_price?.price}
              formState={formState}
              errors={{
                "first_list_price.price":
                  errors?.first_list_price?.price ?? undefined,
              }}
              type="number"
            />
          </div>
        </ListPriceWrapper>
        {fields.map((field, index) => (
          <ListPriceWrapper key={field.id}>
            <TextField
              name={`list_prices[${index}].quantity`}
              label={t("Minimum Units")}
              autoComplete={"quantity"}
              theref={register({
                required: strings(t).thisIsARequiredField,
                pattern: {
                  value: positiveIntegerRegex,
                  message: t("Must be a valid numeric value"),
                },
              })}
              defaultValue={field?.item?.quantity}
              formState={formState}
              errors={{
                [`list_prices[${index}].quantity`]:
                  errors?.list_prices?.[index]?.quantity ?? undefined,
              }}
              type="number"
            />
            <TextField
              name={`list_prices[${index}].price`}
              label={t("Price ($/UoM)")}
              autoComplete={"price"}
              theref={register({
                required: strings(t).thisIsARequiredField,
                min: {
                  value: 0,
                  message: t("Price must be greater than 0"),
                },
              })}
              defaultValue={field?.item?.price}
              formState={formState}
              errors={{
                [`list_prices[${index}].price`]:
                  errors?.list_prices?.[index]?.price ?? undefined,
              }}
              type="number"
            />
            <DeleteButton
              testid={`delete-button-${index}`}
              onClick={() => remove(index)}
              type="button"
              height={20}
              width={20}
            />
          </ListPriceWrapper>
        ))}
        <SecondaryButtonMedium
          type="button"
          style={{ fontSize: "15px" }}
          onClick={() => append({ quantity: undefined, price: undefined })}
        >
          {t("Add List Price")}
        </SecondaryButtonMedium>
        <PrimaryButtonFitContainer
          loading={is_submitting}
          style={{ marginTop: "32px" }}
          type="submit"
        >
          {t("Save")}
        </PrimaryButtonFitContainer>
      </Form>
    </>
  );
};
