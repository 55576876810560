/**
 * Before adding new button styles, run the Storybook documentation
 * to check that the button you want to add does not already exist
 */

import type { ButtonHTMLAttributes, CSSProperties } from "react";
import React, { useContext, useEffect, useState } from "react";
import styled, {
  keyframes,
  ThemeContext,
  useTheme,
} from "styled-components/macro";
import {
  CaretLeft,
  DeleteIcon,
  EditIcon,
  LoadingIcon,
  CheckIcon,
  XIcon,
  PlusIcon,
  DownloadIcon,
  GoBackIconSmall,
  ImportIcon,
  ArchiveIcon,
  RestoreIcon,
  WarningIcon,
  ViewListIcon,
  ViewGridIcon,
  ShareIcon,
  LightCaretRightIcon,
} from "../Icons/Icons";
import {
  ConfirmDialog,
  GenericDialogBody,
} from "../ConfirmDialog/ConfirmDialog";
import { useTranslation } from "react-i18next";
import { DestructiveText, H3 } from "../Typography/Typography";
import { screenSize } from "../../theme";
import { IDMobile } from "../IDs/IDs";
import { ErrorMessageBox } from "../Form/Form";
import ReactTooltip from "react-tooltip";
import { Row } from "../Layout/Layout";
import { TextArea } from "../TextArea/TextArea";
import { Form } from "../../layout/FormLayout";
import { useForm } from "react-hook-form";
import { TooltipCell } from "../../pages/Buyer/BuyerOrders/BuyerOrders.util";

interface TertiaryButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  active?: boolean;
}

const PopupMessageContainer = styled.div<{ isSmallErrorMsgStyle?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 16px;
  margin-bottom: ${({ isSmallErrorMsgStyle }) =>
    isSmallErrorMsgStyle ? "0px" : "32px"};
  gap: 14px;
  font-weight: ${({ theme }) => theme.fontWeights.large};
  color: ${({ theme }) => theme.primaryTextColor};
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

/**
 * A button that always looks enabled, even if it is `disabled` (except for
 * the cursor).
 */
export const ButtonAlwaysLooksEnabled = styled.button`
  border-radius: 4px;
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  text-align: center;
  cursor: pointer;
  :focus {
    outline: none;
  }
  border-width: 0px;
  :disabled {
    cursor: not-allowed;
  }
`;

export const Button = styled(ButtonAlwaysLooksEnabled)`
  :disabled {
    background-color: ${({ theme }) => theme.disabledButtonBG};
    color: ${({ theme }) => theme.disabledButtonTextColor};
    :hover,
    :focus {
      background-color: ${({ theme }) => theme.disabledButtonBG};
    }
  }
`;

interface LoadingButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  loadingIconSize?: number;
  loadingIconFill?: string;
  datafor?: string;
  datatip?: string;
}

/**
 * A button that can show a loading icon (spinner) and is automatically
 * `disabled` when in that loading state.  It does not *look* disabled when it
 * is in the loading state.  If `disabled` is set explicitly on the button then
 * that takes precedence and the button will be disabled and look disabled.
 * The default loading icon size and color can be overridden by passing in props.
 *
 * @param props.loading - True to show the loading icon.
 * @param props.loadingIconSize - Size of the loading icon.
 * @param props.loadingIconFill - Color of the loading icon.
 */
const LoadingButton = ({
  loading,
  loadingIconSize,
  loadingIconFill,
  children,
  ...buttonProps
}: LoadingButtonProps) => {
  const theme = useTheme();

  const finalChildren = loading ? (
    <LoadingIcon
      width={loadingIconSize || 15}
      height={loadingIconSize || 15}
      fill={loadingIconFill || theme.primaryButtonTextColor}
    />
  ) : (
    children
  );

  if (loading && buttonProps.disabled === undefined) {
    return (
      <ButtonAlwaysLooksEnabled
        {...buttonProps}
        disabled
        data-for={buttonProps.datafor}
        data-tip={buttonProps.datatip}
      >
        {finalChildren}
      </ButtonAlwaysLooksEnabled>
    );
  }
  return (
    <Button
      {...buttonProps}
      data-for={buttonProps.datafor}
      data-tip={buttonProps.datatip}
    >
      {finalChildren}
    </Button>
  );
};

// Destructive Buttons
export const DestructiveButton = styled(LoadingButton)`
  background-color: ${({ theme }) => theme.destructiveButtonBG};
  color: ${({ theme }) => theme.destructiveButtonTextColor};
  border: ${({ theme }) => `1px solid ${theme.colors.errorRedBorder}`};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  :hover,
  :focus {
    box-shadow: ${({ theme }) => `0 0 1px 1px ${theme.colors.errorRedBorder}`};
  }
  :disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.primaryButtonBG};
    color: ${({ theme }) => theme.destructiveButtonTextColor};
    border: ${({ theme }) => `1px solid ${theme.colors.errorRedBorder}`};
    opacity: 0.5;
    box-shadow: none;
  }
`;

// Primary Buttons
export const PrimaryButton = styled(LoadingButton)`
  background-color: ${({ theme }) => theme.primaryButtonBG};
  color: ${({ theme }) => theme.primaryButtonTextColor};
  border: ${({ theme }) => `1px solid ${theme.primaryButtonBorder}`};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  :hover,
  :focus {
    background-color: ${({ theme }) => theme.primaryButtonHover};
    box-shadow: ${({ theme }) => `0 0 1px 1px ${theme.primaryButtonBorder}`};
  }

  :active {
    background-color: ${({ theme }) => theme.primaryButtonActive};
  }
  :disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.primaryButtonBG};
    color: ${({ theme }) => theme.primaryButtonTextColor};
    border: ${({ theme }) => `1px solid ${theme.primaryButtonBorder}`};
    opacity: 0.5;
    box-shadow: none;
  }
`;

// The loadingIconSize should generally match the font-size.
export const PrimaryButtonLarge = styled(PrimaryButton).attrs((props) => ({
  loadingIconSize: props.loadingIconSize ? props.loadingIconSize : 15,
}))`
  padding: 11px 15px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.primaryButtonTextColor};
`;

export const DestructiveButtonLarge = styled(PrimaryButtonLarge).attrs(
  (props) => ({
    loadingIconSize: props.loadingIconSize ? props.loadingIconSize : 15,
  })
)`
  background-color: ${({ theme }) => theme.destructiveButtonBG};
  color: ${({ theme }) => theme.destructiveButtonTextColor};
  border: ${({ theme }) => `1px solid ${theme.colors.errorRedBorder}`};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  :hover,
  :focus {
    box-shadow: ${({ theme }) => `0 0 1px 1px ${theme.colors.errorRedBorder}`};
  }
  :disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.primaryButtonBG};
    color: ${({ theme }) => theme.destructiveButtonTextColor};
    border: ${({ theme }) => `1px solid ${theme.colors.errorRedBorder}`};
    opacity: 0.5;
    box-shadow: none;
  }
`;

export const PrimaryButtonMedium = styled(PrimaryButton).attrs((props) => ({
  loadingIconSize: props.loadingIconSize ? props.loadingIconSize : 13,
}))`
  padding: 11px 15px;
  //font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.primaryButtonTextColor};
`;

export const PrimaryButtonMediumExtended = styled(PrimaryButtonMedium)`
  width: 100%;
`;

// TODO: PrimaryButtonMedium and PrimaryButtonSmall appear to be identical.
export const PrimaryButtonSmall = styled(PrimaryButton).attrs((props) => ({
  loadingIconSize: props.loadingIconSize ? props.loadingIconSize : 13,
}))`
  padding: 11px 15px;
  //font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.primaryButtonTextColor};
`;

export const PrimaryButtonXSmall = styled(PrimaryButton).attrs({
  loadingIconSize: 11,
})`
  font-weight: ${({ theme }) => theme.fontWeights.small};
  padding: 6px 8px;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.primaryButtonTextColor};
`;

export const PrimaryButtonFitContainer = styled(PrimaryButtonLarge)`
  width: 100%;
  display: inline-block;
`;

// Secondary Buttons
export const SecondaryButtonLarge = styled(PrimaryButtonLarge)`
  background-color: ${({ theme }) => theme.secondaryButtonBG};
  border: ${({ theme }) => `1px solid ${theme.secondaryButtonBG}`};
  color: ${({ theme }) => theme.secondaryButtonTextColor};
  border: none;

  :hover,
  :focus {
    background-color: ${({ theme }) => theme.secondaryButtonHover};
    border: ${({ theme }) => `1px solid ${theme.secondaryButtonHoverBorder}`};
    box-shadow: ${({ theme }) =>
      `0 0 0 1px ${theme.secondaryButtonHoverBorder}`};
  }

  :active {
    background-color: ${({ theme }) => theme.secondaryButtonActive};
    box-shadow: ${({ theme }) =>
      `0 0 0 2px ${theme.secondaryButtonHoverBorder}`};
  }
  :disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.secondaryButtonBG};
    color: ${({ theme }) => theme.primaryButtonTextColor};
    border: ${({ theme }) => `1px solid ${theme.primaryButtonBorder}`};
    opacity: 0.5;
    box-shadow: none;
  }
`;

export const SecondaryButtonMedium = styled(PrimaryButtonMedium)`
  background-color: ${({ theme }) => theme.secondaryButtonBG};
  color: ${({ theme }) => theme.secondaryButtonTextColor};
  border: none;

  :hover,
  :focus {
    background-color: ${({ theme }) => theme.secondaryButtonHover};
    box-shadow: ${({ theme }) =>
      `0 0 0 2px ${theme.secondaryButtonHoverBorder}`};
  }

  :active {
    background-color: ${({ theme }) => theme.secondaryButtonActive};
    box-shadow: ${({ theme }) =>
      `0 0 0 2px ${theme.secondaryButtonHoverBorder}`};
  }
`;

export const SecondaryButtonSmall = styled(PrimaryButtonSmall)`
  background-color: ${({ theme }) => theme.secondaryButtonBG};
  color: ${({ theme }) => theme.secondaryButtonTextColor};
  border: none;

  :hover,
  :focus {
    background-color: ${({ theme }) => theme.secondaryButtonHover};
    box-shadow: ${({ theme }) =>
      `0 0 0 2px ${theme.secondaryButtonHoverBorder}`};
  }

  :active {
    background-color: ${({ theme }) => theme.secondaryButtonActive};
    box-shadow: ${({ theme }) =>
      `0 0 0 2px ${theme.secondaryButtonHoverBorder}`};
  }
`;

export const SecondaryButtonXSmall = styled(PrimaryButtonXSmall)`
  background-color: ${({ theme }) => theme.secondaryButtonBG};
  color: ${({ theme }) => theme.secondaryButtonTextColor};
  border: none;

  :hover,
  :focus {
    background-color: ${({ theme }) => theme.secondaryButtonHover};
    box-shadow: ${({ theme }) =>
      `0 0 0 2px ${theme.secondaryButtonHoverBorder}`};
  }

  :active {
    background-color: ${({ theme }) => theme.secondaryButtonActive};
    box-shadow: ${({ theme }) =>
      `0 0 2px 1px ${theme.secondaryButtonHoverBorder}`};
  }
`;

export const SecondaryButtonFitContainer = styled(SecondaryButtonLarge)`
  width: 100%;
  display: inline-block;
`;

// Reject Buttons
export const RejectButtonSmall = styled(PrimaryButtonSmall)`
  background-color: ${({ theme }) => theme.destructiveButtonBG};
  color: ${({ theme }) => theme.destructiveButtonTextColor};
  border: none;

  :hover,
  :focus {
    background-color: ${({ theme }) => theme.destructiveButtonHover};
  }

  :active {
    background-color: ${({ theme }) => theme.destructiveButtonActive};
  }
`;

export const RejectButtonXSmall = styled(PrimaryButtonXSmall)`
  background-color: ${({ theme }) => theme.destructiveButtonBG};
  color: ${({ theme }) => theme.destructiveButtonTextColor};
  border: none;

  :hover,
  :focus {
    background-color: ${({ theme }) => theme.destructiveButtonHover};
    box-shadow: 0;
  }

  :active {
    background-color: ${({ theme }) => theme.destructiveButtonActive};
    box-shadow: 0;
  }
`;

// Tertiary Buttons

export const TertiaryButtonMedium = styled(Button)<TertiaryButtonProps>`
  border: solid 1px
    ${({ theme, active }) =>
      active ? theme.selectedBorder : theme.primaryBorder};
  background-color: ${({ theme, active }) =>
    active ? theme.tertiaryButtonSelected : theme.tertiaryButtonBG};
  padding: 9px 12px;
  color: ${({ theme }) => theme.tertiaryButtonTextColor};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  text-decoration: none;
  &:hover,
  :focus {
    border: solid 1px ${({ theme }) => theme.tertiaryBorder};
  }

  &:focus {
    border: solid 1px ${({ theme }) => theme.selectedBorder};
    background-color: ${({ theme }) => theme.tertiaryButtonSelected};
  }
`;

export const TertiaryButtonSmall = styled(TertiaryButtonMedium)`
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.small};
`;

export const TertiaryButtonFitContainer = styled(TertiaryButtonMedium)`
  width: 100%;
`;

export const TertiaryButtonLargePrimaryBG = styled(TertiaryButtonFitContainer)`
  color: ${({ theme }) => theme.brandColor};
  border-color: ${({ theme }) => theme.brandColor};
`;

const GoBackContainer = styled.div`
  display: relative;
`;

const GoBackText = styled.span`
  vertical-align: top;
  display: inline-block;
  cursor: pointer;
  color: ${({ theme }) => theme.primaryLinkColor};
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: ${({ theme }) => theme.fontWeights.large};
  font-family: ${({ theme }) => theme.fontFamily};
`;

const GoBackIcon = styled.div`
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
`;

const TableContainer = styled.div`
  width: 490px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 16px;
  border-radius: 8px;
  border: solid 1px ${({ theme }) => theme.colors.primaryBlue};
  background-color: ${({ theme }) => theme.primaryButtonBG};
  gap: 16px;
`;

const InnerTitleText = styled.div`
  height: 23px;
  flex-grow: 0;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: -0.2px;
  text-align: left;
  color: ${({ theme }) => theme.colors.darkBlue};
  margin-bottom: 16px;
`;

const RowContent = styled.div<{ disabled: boolean }>`
  height: 57px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 16px 16px 17px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  border-left: solid 4px ${({ theme }) => theme.secondaryBorder};
  border-right: solid 1px ${({ theme }) => theme.secondaryBorder};
  border-top: solid 1px ${({ theme }) => theme.secondaryBorder};
  &:first-child {
    border-bottom: solid 1px ${({ theme }) => theme.secondaryBorder};
  }
  &:last-child {
    border-bottom: solid 1px ${({ theme }) => theme.secondaryBorder};
  }
`;

const IconTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const Text = styled.span<{ disabled: boolean }>`
  height: 19px;
  flex-grow: 0;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.grayText : theme.colors.darkBlue};
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const GoBackButton = ({ text }: any) => {
  const themeContext = useContext(ThemeContext);

  return (
    <>
      <GoBackContainer>
        <GoBackIcon>
          <CaretLeft fill={themeContext.primaryLinkColor} />
        </GoBackIcon>
        <GoBackText>{text}</GoBackText>
      </GoBackContainer>
    </>
  );
};

export const GoBackButtonSmall = ({ text }: { text: string | JSX.Element }) => {
  const theme = useTheme();

  return (
    <>
      <GoBackContainer>
        <GoBackIconSmall />
        <GoBackText style={{ color: theme.navIconActive, fontSize: "13px" }}>
          {text}
        </GoBackText>
      </GoBackContainer>
    </>
  );
};

export const CancelButton = styled(LoadingButton)`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.errorColor};
  padding: 17px 13px 17px 33px;
  outline: none;
  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
export const PrimaryCancelButton = styled(CancelButton)`
  border: 1px solid ${({ theme }) => theme.errorColor};
  padding: 11px 15px;
  &:hover {
    box-shadow: ${({ theme }) => `0 0 1px 1px ${theme.errorColor}`};
  }
`;

export const CancelButtonNarrow = styled(CancelButton)`
  padding: 17px 0;
`;

export const TextButton = styled.button`
  vertical-align: middle;
  display: inline-block;
  color: ${({ theme }) => theme["primaryLinkColor"]};
  font-size: ${({ theme }) => theme.fontSizes["medium"]};
  font-weight: ${({ theme }) => theme.fontWeights["medium"]};
  font-family: ${({ theme }) => theme["fontFamily"]};
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  text-wrap: nowrap;
  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  :hover {
    text-decoration: underline;
  }
`;

export const TertiaryTextButtonSmall = styled(TextButton)`
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.small};
`;

export const InvisibleButton = styled.button`
  background: transparent;
  border: none !important;
  cursor: pointer;
  &:active,
  &:hover,
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const AddNewButton = styled.button`
  color: ${({ theme }) => theme.disabledButtonBG};
  background: transparent;
  border: none !important;
  cursor: pointer;
  &:active,
  &:focus {
    outline: none;
  }
`;

const IconButtonContainer = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  border-radius: 25px;
  border: 1px solid transparent;
  align-items: center;
  justify-content: center;
  padding: 3px;
  &:active,
  &:focus,
  &:hover {
    background: ${({ theme }) => theme.primaryButtonHover};
    border: 1px solid ${({ theme }) => theme.primaryButtonBorder};
    path {
      fill: ${({ theme }) => theme.primaryTextColor};
      stroke: ${({ theme }) => theme.primaryTextColor};
    }
  }
`;

const DeleteButtonContainer = styled(IconButtonContainer)`
  path {
    fill: ${({ theme }) => theme.destructiveButtonTextColor};
  }
  &:active,
  &:focus,
  &:hover {
    background: ${({ theme }) => theme.destructiveButtonBG};
    border: 1px solid ${({ theme }) => theme.destructiveButtonTextColor};
    path {
      fill: ${({ theme }) => theme.destructiveButtonTextColor};
      stroke: none;
    }
  }
`;

const ArchiveButtonContainer = styled(DeleteButtonContainer)`
  &:active,
  &:focus,
  &:hover {
    path {
      stroke: ${({ theme }) => theme.destructiveButtonTextColor};
    }
  }
`;

const RestoreButtonContainer = styled(IconButtonContainer)`
  path {
    stroke: ${({ theme }) => theme.primaryTextColor};
  }
`;

// Button Container
export const ButtonGroup = styled.div`
  display: flex;
  gap: 16px;
  margin: 28px 0 0 0;
`;

const CancelTransPopupMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 16px;
  gap: 14px;
  font-weight: ${({ theme }) => theme.fontWeights.large};
  color: ${({ theme }) => theme.primaryTextColor};
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

const CancelTransMessage = styled.span`
  text-align: center;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.darkText};
  font-weight: ${({ theme }) => theme.fontWeights.small};
  line-height: 23px;
`;

const CloseIconAnimation = keyframes`
 0% { opacity: 0; transform: rotate(270deg)}
 50% { opacity: 0.3; }
 100% { opacity: 1; transform: rotate(0deg)}
`;

const CloseButton = styled.button`
  width: 1.2rem;
  height: 1.2rem;
  cursor: pointer;
  border: 0;
  padding: 0;
  background: transparent;
  animation-name: ${CloseIconAnimation};
  animation-duration: 0.4s;
`;

const HeaderContainer = styled.div<{ showAlignCloseButton?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${(showAlignCloseButton) =>
    showAlignCloseButton ? "space-between" : "flex-start"};
`;

/**
 * Enforce using data-testid for buttons wrapping an svg icon to unblock
 * e2e testing
 */
export interface SVGButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  testid?: string;
  width?: number;
  height?: number;
  datafor?: string;
  datatip?: string;
}

export const EditButton = ({
  testid,
  width = 24,
  height = 24,
  ...props
}: SVGButtonProps): JSX.Element => {
  const theme = useContext(ThemeContext);

  return (
    <>
      <InvisibleButton data-testid={testid} {...props}>
        <IconButtonContainer data-for={props.datafor} data-tip={props.datatip}>
          <EditIcon
            width={width}
            height={height}
            fill={
              props.disabled
                ? theme.disabledButtonTextColor
                : theme.primaryIconColor
            }
          />
        </IconButtonContainer>
      </InvisibleButton>
    </>
  );
};

export const DownloadButton = ({
  testid,
  width = 24,
  height = 24,
  ...props
}: SVGButtonProps): JSX.Element => {
  const theme = useContext(ThemeContext);

  return (
    <>
      <InvisibleButton data-testid={testid} {...props}>
        <IconButtonContainer>
          <DownloadIcon
            width={width}
            height={height}
            fill={
              props.disabled ? theme.disabledButtonBG : theme.secondaryTextColor
            }
          />
        </IconButtonContainer>
      </InvisibleButton>
    </>
  );
};

export const AddNewIconButton = (props: SVGButtonProps): JSX.Element => {
  const theme = useContext(ThemeContext);

  return (
    <>
      <InvisibleButton data-testid={props.testid} {...props}>
        <PlusIcon width={16} height={16} fill={theme.primaryTextColor} />
      </InvisibleButton>
    </>
  );
};

export const AddNewButtonSmall = (
  props: ButtonHTMLAttributes<HTMLButtonElement>
): JSX.Element => {
  const theme = useContext(ThemeContext);
  return (
    <AddNewButton {...props}>
      <PlusIcon width={13} height={13} fill={theme.primaryTextColor} /> Add new
    </AddNewButton>
  );
};

export const PrimaryButtonWithPlusIcon = (
  props: SVGButtonProps
): JSX.Element => {
  const theme = useTheme();
  return (
    <PrimaryButtonMedium {...props}>
      <span
        data-for={props.datafor}
        data-tip={props.datatip}
        style={{ display: "flex", alignItems: "center", gap: "4px" }}
      >
        <PlusIcon width={20} height={20} fill={theme.primaryButtonTextColor} />
        <span>{props.children}</span>
      </span>
    </PrimaryButtonMedium>
  );
};

export const CallToActionButton = styled(PrimaryButtonFitContainer)`
  border: 2px solid ${(props) => props.theme.selectedBorder};
  border-radius: 4px;
  font-weight: ${(props) => props.theme.fontWeights.medium};
  padding: 10px 20px;
  box-shadow: 0px 0px 8px #00000040;
  text-decoration: none;
  @media ${screenSize.small} {
    padding: 5px;
  }
`;

export const AgilisButton = (props: LoadingButtonProps): JSX.Element => {
  return (
    <CallToActionButton loadingIconSize={17} {...props}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "4px",
          alignItems: "center",
        }}
      >
        <img
          src="https://cdn.agilischemicals.com/assets/Agilis_Logo_rainbow.svg"
          width="27"
          style={{ verticalAlign: "middle", marginBottom: "3px" }}
          alt="Agilis Logo"
        />
        <span>{props.children}</span>
      </div>
    </CallToActionButton>
  );
};

export const SecondaryButtonWithPlusIcon = (
  props: SVGButtonProps
): JSX.Element => {
  const theme = useTheme();
  return (
    <SecondaryButtonMedium {...props}>
      <span
        data-for={props.datafor}
        data-tip={props.datatip}
        style={{ display: "flex", alignItems: "center", gap: "4px" }}
      >
        <PlusIcon width={20} height={20} fill={theme.primaryButtonTextColor} />
        <span>{props.children}</span>
      </span>
    </SecondaryButtonMedium>
  );
};

export const SecondaryButtonWithImportIcon = (
  props: SVGButtonProps
): JSX.Element => {
  return (
    <SecondaryButtonMedium {...props}>
      <span
        data-for={props.datafor}
        data-tip={props.datatip}
        style={{ display: "flex", alignItems: "center", gap: "8px" }}
      >
        <ImportIcon width={17} height={18} />
        <span>{props.children}</span>
      </span>
    </SecondaryButtonMedium>
  );
};

export const CancelButtonSmall = (
  props: ButtonHTMLAttributes<HTMLButtonElement> & { cancelText?: string }
): JSX.Element => {
  const { t } = useTranslation();
  return (
    <CancelButtonNarrow {...props}>
      {props.cancelText ? props.cancelText : t("Cancel")}
    </CancelButtonNarrow>
  );
};

export const DeleteButton = ({
  testid,
  datafor,
  datatip,
  width = 16,
  height = 16,
  ...props
}: SVGButtonProps) => {
  const theme = useContext(ThemeContext);
  const fill = theme.destructiveButtonBG;

  return (
    <InvisibleButton data-testid={testid} {...props}>
      <DeleteButtonContainer data-for={datafor} data-tip={datatip}>
        <DeleteIcon width={width} height={height} fill={fill} />
      </DeleteButtonContainer>
    </InvisibleButton>
  );
};

export const SaveIconButton = ({
  testid,
  datafor,
  datatip,
  width = 24,
  height = 24,
  ...props
}: SVGButtonProps) => {
  const theme = useContext(ThemeContext);
  const fill = theme.activeToggleBG;

  return (
    <InvisibleButton data-testid={testid} {...props}>
      <div data-for={datafor} data-tip={datatip}>
        <CheckIcon width={width} height={height} fill={fill} />
      </div>
    </InvisibleButton>
  );
};

export const ArchiveButton = ({
  testid,
  datafor,
  datatip,
  width = 16,
  height = 16,
  ...props
}: SVGButtonProps) => {
  const theme = useContext(ThemeContext);
  const fill = theme.destructiveButtonTextColor;

  return (
    <InvisibleButton data-testid={testid} {...props}>
      <ArchiveButtonContainer data-for={datafor} data-tip={datatip}>
        <ArchiveIcon width={width} height={height} fill={fill} />
      </ArchiveButtonContainer>
    </InvisibleButton>
  );
};

export const RestoreButton = ({
  testid,
  datafor,
  datatip,
  width = 16,
  height = 16,
  ...props
}: SVGButtonProps) => {
  const theme = useContext(ThemeContext);
  const fill = theme.destructiveButtonBG;

  return (
    <InvisibleButton data-testid={testid} {...props}>
      <RestoreButtonContainer data-for={datafor} data-tip={datatip}>
        <RestoreIcon width={width} height={height} fill={fill} />
      </RestoreButtonContainer>
    </InvisibleButton>
  );
};

export const CheckButton = (props: SVGButtonProps) => {
  const theme = useContext(ThemeContext);
  return (
    <InvisibleButton data-testid={props.testid} {...props}>
      <CheckIcon width={24} height={24} fill={theme.successIconColor} />
    </InvisibleButton>
  );
};

export const XButton = (props: SVGButtonProps) => {
  const theme = useContext(ThemeContext);
  return (
    <InvisibleButton data-testid={props.testid} {...props}>
      <DeleteButtonContainer>
        <XIcon width={24} height={24} fill={theme.destructiveButtonTextColor} />
      </DeleteButtonContainer>
    </InvisibleButton>
  );
};

const DeleteButtonWithTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DeleteButtonWithText = ({
  width = 22,
  height = 22,
  ...props
}: SVGButtonProps) => {
  const theme = useTheme();
  const fill = theme.destructiveTextColor;

  return (
    <InvisibleButton type="button" {...props}>
      <DeleteButtonWithTextContainer
        data-for={props.datafor}
        data-tip={props.datatip}
      >
        <DeleteIcon width={width} height={height} fill={fill} />
        <DestructiveText>{props.children}</DestructiveText>
      </DeleteButtonWithTextContainer>
    </InvisibleButton>
  );
};

export interface BlockLeadFormData {
  report_spam?: boolean;
  block_email?: boolean;
  block_phone_number?: boolean;
  block_domain?: boolean;
  confirm_convert_to_lead?: boolean;
}

export const ButtonWithWarningDialog = ({
  Button,
  testid,
  heading,
  id,
  confirmMessage,
  handleConfirm,
  handleCancel,
  disabled,
  type = "button",
  buttonText,
  loading,
  showSecondButton = false,
  saveBtnTitle,
  showAlignCloseButton = false,
  isSmallErrorMsgStyle = false,
  showBlockLeadCheckBox = false,
  openModalDirectly = false,
  renderCheckboxes,
}: {
  Button?: (props: SVGButtonProps) => JSX.Element | JSX.Element;
  testid: string;
  confirmMessage: string;
  heading: string;
  id?: string;
  handleConfirm?: () => void;
  handleCancel?: () => void;
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  buttonText?: string;
  loading?: boolean;
  showSecondButton?: boolean;
  saveBtnTitle?: string;
  showAlignCloseButton?: boolean;
  isSmallErrorMsgStyle?: boolean;
  showBlockLeadCheckBox?: boolean;
  openModalDirectly?: boolean;
  renderCheckboxes?: (setShow: (show: boolean) => void) => JSX.Element;
}) => {
  const [show, setShow] = useState(openModalDirectly);
  const theme = useTheme();
  const { t } = useTranslation();

  const getCloseDialogHandler = () => {
    return handleCancel
      ? () => {
          handleCancel();
          setShow(false);
        }
      : () => setShow(false);
  };

  return (
    <>
      {Button && (
        <Button
          onClick={() => {
            setShow(true);
          }}
          testid={testid}
          disabled={disabled}
          type={type}
        >
          {loading ? (
            <LoadingIcon
              width={15}
              height={15}
              fill={theme.primaryButtonTextColor}
            />
          ) : (
            buttonText && buttonText
          )}
        </Button>
      )}
      <GenericDialogBody
        show={show}
        closeDialog={getCloseDialogHandler()}
        showCloseButton={!showAlignCloseButton}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "28px" }}>
          <HeaderContainer showAlignCloseButton={showAlignCloseButton}>
            <H3 style={{ display: "flex", gap: "8px", margin: 0 }}>
              {heading}
              {id ? (
                <IDMobile style={{ display: "inline", marginLeft: "8px" }}>
                  {id}
                </IDMobile>
              ) : (
                <></>
              )}
            </H3>
            {showAlignCloseButton && (
              <CloseButton onClick={getCloseDialogHandler()}>
                <XIcon />
              </CloseButton>
            )}
          </HeaderContainer>
          <ErrorMessageBox>
            <PopupMessageContainer isSmallErrorMsgStyle={isSmallErrorMsgStyle}>
              <WarningIcon width={33} height={33} />
              {isSmallErrorMsgStyle ? (
                <CancelTransMessage>{confirmMessage}</CancelTransMessage>
              ) : (
                <p style={{ textAlign: "center" }}>{confirmMessage}</p>
              )}
            </PopupMessageContainer>
          </ErrorMessageBox>

          {showBlockLeadCheckBox &&
            renderCheckboxes &&
            renderCheckboxes(setShow)}

          {!showBlockLeadCheckBox && (
            <div style={{ display: "flex", gap: "16px" }}>
              {showSecondButton && (
                <SecondaryButtonMedium
                  style={{ flex: 1 }}
                  onClick={getCloseDialogHandler()}
                >
                  {t("Cancel")}
                </SecondaryButtonMedium>
              )}
              <PrimaryButtonMedium
                style={{ flex: 1 }}
                onClick={
                  handleConfirm
                    ? () => {
                        handleConfirm();
                        setShow(false);
                      }
                    : () => setShow(false)
                }
              >
                {showSecondButton ? saveBtnTitle : t("Continue")}
              </PrimaryButtonMedium>
            </div>
          )}
        </div>
      </GenericDialogBody>
    </>
  );
};

/**
 * Renders a button and shows a confirmation dialog when the button is clicked.
 * Notice the lack of "-" between datatip and datafor. This is because aria and
 * data-* properties are not type checked with typescript jsx
 * https://github.com/microsoft/TypeScript/issues/32447
 */
export const ButtonWithConfirmDialog = ({
  Button,
  testid,
  confirmMessage,
  handleConfirm,
  handleCancel,
  disabled,
  type = "button",
  buttonText,
  loading,
  datatip,
  datafor,
}: {
  Button: (props: SVGButtonProps) => JSX.Element;
  testid: string;
  confirmMessage: string;
  handleConfirm?: () => void;
  handleCancel?: () => void;
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  buttonText?: string;
  loading?: boolean;
  datatip?: string;
  datafor?: string;
}) => {
  const [show, setShow] = useState(false);
  const theme = useTheme();
  return (
    <>
      <Button
        onClick={() => {
          setShow(true);
        }}
        testid={testid}
        disabled={disabled}
        type={type}
        data-tip={datatip}
        data-for={datafor}
        datatip={datatip} // for button types like PrimaryButtonFitContainer
        datafor={datafor}
      >
        {loading ? (
          <LoadingIcon
            width={15}
            height={15}
            fill={theme.primaryButtonTextColor}
          />
        ) : (
          buttonText && buttonText
        )}
      </Button>
      {datatip && <ReactTooltip id={datafor} effect="solid" />}
      <ConfirmDialog
        show={show}
        closeDialog={() => setShow(false)}
        confirmMessage={confirmMessage}
        handleConfirm={
          handleConfirm
            ? () => {
                handleConfirm();
                setShow(false);
              }
            : undefined
        }
        handleCancel={
          handleCancel
            ? () => {
                handleCancel();
                setShow(false);
              }
            : undefined
        }
      />
    </>
  );
};

type formOutput = {
  reasonForCancellation: string;
};

export const CancelTransactionDialogButton = ({
  Button,
  testid,
  heading,
  confirmMessage,
  handleConfirm,
  disabled,
  type = "button",
  cancellationReason,
  isCancellationPending,
}: {
  Button: (props: SVGButtonProps) => JSX.Element | JSX.Element;
  testid: string;
  confirmMessage: string;
  heading: string;
  // handleConfirm?: () => void;
  handleConfirm?: (values: formOutput) => void;
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  cancellationReason?: string;
  isCancellationPending?: boolean;
}) => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  const { formState, register, handleSubmit, errors } = useForm();

  const onSubmit = (values: formOutput) => {
    setShow(false);
    if (handleConfirm) {
      handleConfirm(values);
    }
  };

  return (
    <>
      <Button
        onClick={() => {
          setShow(true);
        }}
        testid={testid}
        disabled={disabled}
        type={type}
      ></Button>
      <GenericDialogBody
        show={show}
        closeDialog={() => setShow(false)}
        showCloseButton={false}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "28px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <H3 style={{ display: "flex", gap: "8px", margin: 0 }}>
              {heading}
            </H3>
            <CloseButton onClick={() => setShow(false)}>
              <XIcon />
            </CloseButton>
          </div>
          <ErrorMessageBox>
            <CancelTransPopupMessageContainer>
              <WarningIcon width={33} height={33} />
              <CancelTransMessage>{confirmMessage}</CancelTransMessage>
            </CancelTransPopupMessageContainer>
          </ErrorMessageBox>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <TextArea
              name={"reasonForCancellation"}
              label={t("Reason for cancellation")}
              theref={register({ required: true })}
              formState={formState}
              required={false}
              errors={errors}
              defaultValue={isCancellationPending ? cancellationReason : ""}
              disabled={isCancellationPending}
            />
            <DestructiveButtonLarge
              style={{ width: "100%", margin: "14px 0 0 0" }}
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                handleSubmit(onSubmit)();
              }}
            >
              {isCancellationPending
                ? t("Confirm cancellation")
                : t("Request cancellation")}
            </DestructiveButtonLarge>
          </Form>
        </div>
      </GenericDialogBody>
    </>
  );
};

/**
 * Put `<PrimaryButtonXSmall>` and friends in here as direct children to
 * create a well-spaced row of buttons. For example, in a table cell.
 */
export const XSmallButtonRow = styled.div`
  & button {
    margin-left: 15px;
  }
  & button:first-child {
    margin-left: 0;
  }
`;

/**
 * A row with consistent spacing for `PrimaryButtonSmall` and friends
 */
export const SmallButtonRow = styled.div`
  & button {
    margin-left: 16px;
  }
  & button:first-child {
    margin-left: 0;
  }
`;

/**
 * Cancel or Confirm Buttons evenly spaced.
 */

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const ConfirmOrCancelButtonContainer = ({
  onCancel,
  onConfirm,
  cancelText,
  confirmText,
  isCancelLoading,
  isCancelDisabled,
  isConfirmLoading,
  isConfirmDisabled,
  extraStyle,
}: {
  onCancel: () => void;
  onConfirm: () => void;
  cancelText?: string;
  confirmText?: string;
  isCancelLoading?: boolean;
  isCancelDisabled?: boolean;
  isConfirmLoading?: boolean;
  isConfirmDisabled?: boolean;
  extraStyle?: CSSProperties;
}) => {
  const { t } = useTranslation();
  return (
    <ButtonContainer style={extraStyle}>
      <PrimaryCancelButton
        style={{ flex: 1 }}
        onClick={onCancel}
        disabled={isCancelDisabled}
        loading={isCancelLoading}
        type="reset"
      >
        {cancelText ?? t("Cancel")}
      </PrimaryCancelButton>
      <PrimaryButtonMedium
        style={{ flex: 1 }}
        disabled={isConfirmDisabled}
        loading={isConfirmLoading}
        onClick={onConfirm}
      >
        {confirmText ?? t("Continue")}
      </PrimaryButtonMedium>
    </ButtonContainer>
  );
};

const RowFlexEnd = styled(Row)`
  justify-content: flex-end;
  margin-top: 20px;
  & button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  & button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const TertiaryButtonMediumFlex = styled(TertiaryButtonMedium)`
  gap: 4.5px;
  display: flex;
  align-items: center;
  background-color: ${({ theme, active }) =>
    active ? theme.primaryButtonBG : theme.tertiaryButtonBG};
  &:focus {
    border: solid 1px ${({ theme }) => theme.selectedBorder};
    background-color: ${({ theme, active }) =>
      active ? theme.primaryButtonBG : theme.tertiaryButtonSelected};
  }
`;

export const ListGridToggleButton = ({
  set_toggle_state: update_toggle_state,
  name,
  style,
}: {
  set_toggle_state: React.Dispatch<React.SetStateAction<"list" | "grid">>;
  name: string;
  style?: CSSProperties;
}) => {
  const [toggle_state, set_toggle_state] = useState<"list" | "grid">();
  const { t } = useTranslation();

  const on_toggle_change = (new_state: "list" | "grid") => {
    set_toggle_state(new_state);
    update_toggle_state(new_state);
    sessionStorage.setItem(`view_type_${name}`, new_state);
  };

  useEffect(() => {
    if (sessionStorage.getItem(`view_type_${name}`) && !toggle_state) {
      update_toggle_state(
        sessionStorage.getItem(`view_type_${name}`) as "list" | "grid"
      );
      set_toggle_state(
        sessionStorage.getItem(`view_type_${name}`) as "list" | "grid"
      );
    }
    if (!sessionStorage.getItem(`view_type_${name}`) && !toggle_state) {
      set_toggle_state("list");
      update_toggle_state("list");
    }
  }, [name, toggle_state, update_toggle_state]);

  return (
    <RowFlexEnd style={style}>
      <TertiaryButtonMediumFlex
        onClick={() => {
          on_toggle_change("list");
        }}
        active={toggle_state === "list"}
      >
        <ViewListIcon width={16} height={16} />
        {t("List")}
      </TertiaryButtonMediumFlex>
      <TertiaryButtonMediumFlex
        onClick={() => {
          on_toggle_change("grid");
        }}
        active={toggle_state === "grid"}
      >
        <ViewGridIcon width={16} height={16} />
        {t("Grid")}
      </TertiaryButtonMediumFlex>
    </RowFlexEnd>
  );
};

export const PrimaryButtonWithShareIcon = (
  props: SVGButtonProps
): JSX.Element => {
  const theme = useTheme();
  return (
    <PrimaryButtonMedium {...props}>
      <span
        data-for={props.datafor}
        data-tip={props.datatip}
        style={{ display: "flex", alignItems: "center", gap: "4px" }}
      >
        <ShareIcon width={20} height={20} fill={theme.primaryButtonTextColor} />
        <span>{props.children}</span>
      </span>
    </PrimaryButtonMedium>
  );
};

export interface ShareOption {
  label: string;
  icon: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
}

export const ShareDialogButton = ({
  isOpen,
  onClose,
  heading,
  internalItems,
  externalItems,
  isConnectionOn,
  isLinkDisabled,
}: {
  isOpen: boolean;
  onClose: () => void;
  heading: string;
  internalItems: ShareOption[];
  externalItems: ShareOption[];
  isConnectionOn: boolean | undefined;
  isLinkDisabled: boolean;
}) => {
  const { t } = useTranslation();

  const ShareRow = ({ data }: { data: ShareOption }) => (
    <TooltipCell data-for={`${data.label}-tooltip`} data-tip={""}>
      <RowContent
        onClick={!data.disabled ? data.onClick : undefined}
        disabled={!!data.disabled}
      >
        <IconTextContainer>
          {data.icon}
          <Text disabled={!!data.disabled}>{data.label}</Text>
        </IconTextContainer>
        <IconContainer>
          <LightCaretRightIcon />
        </IconContainer>
        {data.disabled && (
          <ReactTooltip id={`${data.label}-tooltip`} effect="solid">
            {isLinkDisabled
              ? "Publish this product to use this share option"
              : !isConnectionOn
              ? "Enable the connection to use this share option"
              : ""}
          </ReactTooltip>
        )}
      </RowContent>
    </TooltipCell>
  );

  const ShareSection = ({
    title,
    items,
  }: {
    title: string;
    items: ShareOption[];
  }) => (
    <div>
      <InnerTitleText>{title}</InnerTitleText>
      {items.map((item, index) => (
        <ShareRow key={index} data={item} />
      ))}
    </div>
  );

  return (
    <GenericDialogBody
      show={isOpen}
      closeDialog={onClose}
      showCloseButton={false}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "28px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <H3 style={{ display: "flex", gap: "8px", margin: 0 }}>{heading}</H3>
          <CloseButton onClick={onClose}>
            <XIcon />
          </CloseButton>
        </div>
        <TableContainer>
          <ShareSection
            title={t("Share internal view")}
            items={internalItems}
          />
          <ShareSection
            title={t("Share external view")}
            items={externalItems}
          />
        </TableContainer>
      </div>
    </GenericDialogBody>
  );
};

/**
 * Before adding new button styles, run the Storybook documentation
 * to check that the button you want to add does not already exist
 */
