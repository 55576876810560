import * as yup from "yup";
import {
  ButtonWithWarningDialog,
  CancelButton,
  PrimaryButtonMedium,
  SecondaryButtonMedium,
} from "../../../components/Buttons/Buttons";
import type { BlockLeadFormData } from "../../../components/Buttons/Buttons";
import { useTranslation } from "react-i18next";
import { CheckBoxContainer } from "../../../components/Form/Form";
import { CheckBoxNoLabel } from "../../../components/CheckBoxes/CheckBoxes";
import { useFormWrapper } from "../../../util/util";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "styled-components";
import type { DeepMap, FieldError, FieldValues } from "react-hook-form";
import type { BlockLeadData } from "../../../types/types";
import { useEffect } from "react";

export const CheckboxContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export const CheckBoxPrintLabel = styled.label<{ hasError?: boolean }>`
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0;
  color: ${({ theme, hasError }) =>
    hasError ? theme.colors.darkText : theme.colors.grayText};
  cursor: pointer;
`;

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.errorColor};
  font-size: 12px;
`;
interface BlockLeadFormValues extends FieldValues {
  report_spam: boolean;
  block_email: boolean;
  block_phone_number: boolean;
  block_domain: boolean;
}
interface BlockLeadFormErrors extends DeepMap<BlockLeadFormValues, FieldError> {
  at_least_one_checked?: FieldError;
}

export const LeadToBlockModal = ({
  handleBlockLead,
  blockLeadData,
}: {
  handleBlockLead: (
    data: BlockLeadFormData,
    setShow: (show: boolean) => void
  ) => void;
  blockLeadData?: BlockLeadData;
}) => {
  const { t } = useTranslation();

  const blockLeadValidationSchema = yup
    .object()
    .shape({
      report_spam: yup.bool(),
      block_email: yup.bool(),
      block_phone_number: yup.bool(),
      block_domain: yup.bool(),
    })
    .test(
      "at_least_one_checked",
      t("At least one checkbox must be selected"),
      function (value) {
        const { block_email, block_phone_number, block_domain } = value as any;

        if (![block_email, block_phone_number, block_domain].includes(true)) {
          return this.createError({
            message: t("At least one checkbox must be selected"),
          });
        }

        return true;
      }
    );

  const blockLeadDefaultValue = {
    report_spam: false,
    block_email: false,
    block_phone_number: false,
    block_domain: false,
  };

  const blockLeadCheckBox = [
    {
      name: "report_spam",
      label: t("Report spam"),
      required: false,
    },
    {
      name: "block_email",
      label: t("Block email"),
      required: true,
    },
    {
      name: "block_phone_number",
      label: t("Block phone number"),
      required: true,
    },
    {
      name: "block_domain",
      label: t("Block domain"),
      required: true,
    },
  ];

  const { register, handleSubmit, errors, watch, reset } = useFormWrapper<
    BlockLeadFormValues,
    BlockLeadFormErrors
  >({
    resolver: yupResolver(blockLeadValidationSchema),
    defaultValues: blockLeadDefaultValue || {},
  });

  useEffect(() => {
    if (blockLeadData) {
      const newBlockLeadDefaultValue = {
        report_spam:
          blockLeadData?.items?.some((block) => block.is_spam) ?? false,
        block_email:
          blockLeadData?.items?.some((block) => block.block_type === "email") ??
          false,
        block_phone_number:
          blockLeadData?.items?.some((block) => block.block_type === "phone") ??
          false,
        block_domain:
          blockLeadData?.items?.some(
            (block) => block.block_type === "domain"
          ) ?? false,
      };
      reset(newBlockLeadDefaultValue);
    }
  }, [blockLeadData, reset]);

  const checkboxValues: BlockLeadFormData = watch([
    "block_email",
    "block_phone_number",
    "block_domain",
  ]);

  const isAnyChecked: boolean = Object.values(checkboxValues).some(
    (value) => value === true
  );

  const validationError: boolean = errors.at_least_one_checked && !isAnyChecked;

  const validationMessage = errors?.at_least_one_checked;

  const renderCheckboxes = (setShow: (show: boolean) => void) => {
    return (
      <>
        <CheckboxContainerWrapper>
          {blockLeadCheckBox.map((checkbox, index) => (
            <CheckBoxContainer key={index}>
              <div style={{ minWidth: "12px" }}>
                <CheckBoxNoLabel
                  name={checkbox.name}
                  id={checkbox.name}
                  ref={register({ required: checkbox.required })}
                  style={{ marginRight: "10px" }}
                  error={checkbox.required && validationError}
                />
              </div>
              <CheckBoxPrintLabel
                htmlFor={checkbox.name}
                hasError={checkbox.required && validationError}
              >
                {checkbox.label}
              </CheckBoxPrintLabel>
            </CheckBoxContainer>
          ))}
          {validationError && (
            <ErrorMessage>{t(validationMessage?.message)}</ErrorMessage>
          )}
        </CheckboxContainerWrapper>

        <div style={{ display: "flex", gap: "16px" }}>
          <SecondaryButtonMedium
            style={{ flex: 1 }}
            onClick={() => setShow(false)}
          >
            {t("Cancel")}
          </SecondaryButtonMedium>
          <PrimaryButtonMedium
            style={{ flex: 1 }}
            onClick={handleSubmit((data) => handleBlockLead(data, setShow))}
            disabled={validationError}
          >
            {t("Continue")}
          </PrimaryButtonMedium>
        </div>
      </>
    );
  };

  return (
    <ButtonWithWarningDialog
      Button={(props) => (
        <CancelButton style={{ paddingLeft: "10px" }} onClick={props.onClick}>
          {t("Block This Lead")}
        </CancelButton>
      )}
      testid={`close_upload`}
      confirmMessage={t(
        `Blocking this lead will prevent any new lead requests from any users with this email address, phone number, or domain.`
      )}
      heading={t("Block Lead")}
      showSecondButton
      saveBtnTitle={t("Block Lead")}
      showAlignCloseButton
      isSmallErrorMsgStyle
      showBlockLeadCheckBox
      renderCheckboxes={renderCheckboxes}
    />
  );
};
