import { yupResolver } from "@hookform/resolvers/yup";
import Axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { FormProvider, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import useSWR from "swr";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import * as z from "zod";
import { PrimaryButtonLarge } from "../../../components/Buttons/Buttons";
import { SectionTitle } from "../../../components/Form/Form";
import { Notifications } from "../../../components/Notifications/NotificationsContext";
import { productApplicationSchema } from "../../../components/ProductApplicationSelect/ProductApplicationSelect";
import { customPackagingOption } from "../../../components/quoteCart/BuyerQuoteItemFormParts";
import {
  SoftHeaderPrimaryTextColor,
  Title,
} from "../../../components/Typography/Typography";
import { endpoints } from "../../../endpoints";
import { Form } from "../../../layout/FormLayout";
import { Card } from "../../../layout/portalPageLayout";
import type {
  CurrencyCode,
  Fee,
  IPackagingType,
  IPackagingUnit,
  IPriceTier,
  IPriceTierPaginatedOutput,
  ITenantCustomerSettings,
  OptionType,
  Product,
  Tenant,
  UUID,
} from "../../../types/types";
import { calculatePriceTiersPricePerUom } from "../../../util/QuotesAndOrders";
import { positiveIntegerRegex } from "../../../util/regexes";
import {
  packagingTypeOrUnitToOption,
  usePackagingUnits,
  useStorefrontPackagingTypes,
} from "../../../util/SkuUtils";
import { strings } from "../../../util/strings";
import {
  POCFormSchema,
  POCManualFormSchema,
  addressToOption,
  convertApplicationToOption,
  convertPaymentModeToOption,
  convertProductSKUToOption,
  isAxiosError,
  makeUrlWithParams,
  useCurrency,
  useFormWrapper,
  useStoreState,
} from "../../../util/util";
import { formatPrice } from "../../../util/util-components";
import { configured_checkboxes_schema_yup } from "../../admin/SellerAdmin/SellerAdminSettings/TermsTab/ConfigureCheckboxsCard/utils";
import {
  RequestAddress,
  getRequestAddressSchema,
} from "../../public/RequestAddress/RequestAddress";
import { useAddLocation } from "../../SharedPages/OrganizationPage/CompanyInfo/Edit/EditLocations";
import type { LocationInputs } from "../../SharedPages/OrganizationPage/CompanyInfo/Edit/LocationForm";
import { PointOfContact } from "../../SharedPages/OrganizationPage/CompanyInfo/Edit/PointOfContactForm";
import type {
  AddOrEditProductFormOutput,
  InlineDisplayCustomSKU,
} from "../SellerCreateOrder/AddOrEditProductForm";
import { AddOrEditProductForm } from "../SellerCreateOrder/AddOrEditProductForm";
import type { OrderItemToBeCreated } from "../SellerCreateOrder/CreateOrderItemCard";
import { CreateOrderItemCard } from "../SellerCreateOrder/CreateOrderItemCard";
import type { CustomerDetailsFormOutput } from "../SellerCreateOrder/CustomerDetailsForm";
import { CustomerDetailsForm } from "../SellerCreateOrder/CustomerDetailsForm";
import { getUserDetailsFromTenant } from "../SellerCreation.constants";
import type {
  CreateQuoteBottomFormOutput,
  CreateQuoteBottomFormPOCForm,
  CreateQuoteBottomFormPOCManual,
} from "./CreateQuoteBottomForm";
import { CreateQuoteBottomForm } from "./CreateQuoteBottomForm";
import { QuoteFees } from "./QuoteFees";

const Container = styled.section`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 36px;
`;

const FormSection = styled.section`
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  &:nth-child(0) {
    margin-bottom: 40px !important;
  }
  &:nth-child(1) {
    margin-bottom: 0 !important;
  }
  margin-bottom: 20px;
`;

const MainSection = styled.section`
  overflow-y: auto;
`;

const TitleReset = styled(Title)`
  margin-inline-start: 0px;
  margin-block-start: 0;
  margin-inline-end: 0px;
  font-size: ${({ theme }) => theme.fontSizes.xl};
`;

const CardNoMargin = styled(Card)`
  margin-top: 0;
`;

const SectionTitleNoMargin = styled(SectionTitle)`
  margin-top: 0px;
`;

const OrderItemArea = styled.div`
  margin-bottom: 40px;
`;

const AddressWrapper = styled(OrderItemArea)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 500px;
`;

const SubTotalSection = styled.div`
  display: grid;
  width: 100%;
  grid-template-areas: ". subtotal value";
  margin-bottom: 24px;
`;

const SubTotalTitle = styled(SoftHeaderPrimaryTextColor)`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  justify-self: flex-end;
  grid-area: subtotal;
`;

const SubTotalValue = styled(SoftHeaderPrimaryTextColor)`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  justify-self: flex-end;
  grid-area: value;
`;

const TermsAndFeesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

const BottomFormArea = styled.div`
  flex: 1;
`;

const QuoteFeesContainer = styled.div`
  flex: 1;
`;

const QuoteCreationSchema = z.object({
  delivery_term_id: z.string(),
  payment_term_id: z.string(),
  payment_mode_id: z.string(),
  buyer_id: z.string(),
  required_eta: z.string(),
  shipping_address_id: z.string(),
  items: z
    .object({
      product_id: z.string(),
      sku: z.object({
        id: z.string(),
        packaging_unit_id: z.string(),
        packaging_type_id: z.string(),
      }),
      price_per_unit: z.number(),
      no_of_units: z.number(),
      total_quantity: z.number(),
      applications: z.string(),
    })
    .array(),
  transaction_source: z.enum(["Phone", "Email", "Text", "Fax"]).optional(),
  is_tax_exempt: z.boolean(),
  valid_to_date: z.string(),
  fees_to_add: z
    .object({
      id: z.string(),
      name: z.string(),
      amount: z.number(),
      currency: z.string(),
    })
    .array(),
});

type SellerCreateQuotePOST = z.infer<typeof QuoteCreationSchema>;

export const SellerCreateQuote = ({ onSuccess }: { onSuccess: () => void }) => {
  const { t } = useTranslation();
  const {
    tenant_id,
    storefront_id,
    storefront_metadata: { configured_checkboxes },
  } = useStoreState();
  const { notifyError, notifySuccess } = useContext(Notifications);
  const [selectedBuyer, setSelectedBuyer] = useState<Tenant | null>(null);
  const [quoteItems, setQuoteItems] = useState<OrderItemToBeCreated[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const customerDetailsIsDisabled = quoteItems.length > 0;
  const [customerSettings, setCustomerSettings] =
    useState<ITenantCustomerSettings | null>(null);
  const [product, setProduct] = useState<Product | null>(null);
  const [formData, setFormData] = useState<{
    customer: CustomerDetailsFormOutput | null;
    bottomForm: CreateQuoteBottomFormOutput | null;
  }>({ customer: null, bottomForm: null });
  const [selectedItemIndex, setSelectedItemIndex] =
    useState<number | null>(null);
  const [pointOfContactType, setPointOfContactType] =
    useState<"manual" | "existing">("existing");
  const [priceTiers, setPriceTiers] = useState<IPriceTier[]>([]);
  const isEditMode = selectedItemIndex !== null;

  const { packagingTypes } = useStorefrontPackagingTypes();
  const { packagingUnits } = usePackagingUnits();
  const addLocation = useAddLocation({
    buyer_tenant_id: selectedBuyer?.id ?? "",
  });

  const yupSharedSchema = {
    product_id: Yup.string()
      .nullable()
      .required(strings(t).thisIsARequiredField),
    applications: Yup.array().notRequired(),
    ...productApplicationSchema(t),
  };

  const yupSchemaNoPackaging = Yup.object()
    .shape({
      ...yupSharedSchema,
      packaging: Yup.object()
        .shape({
          value: Yup.string().required(strings(t).thisIsARequiredField),
        })
        .required(strings(t).thisIsARequiredField),
      unit_of_measure: Yup.object().required(strings(t).thisIsARequiredField),
      price_per_unit: Yup.string()
        .nullable()
        .required(strings(t).thisIsARequiredField),
    })
    .defined();

  const mustBeIntegerErrorMessage = t("Must be an integer value");

  const yupSchemaCustomPackaging = Yup.object()
    .shape({
      ...yupSharedSchema,
      packaging: Yup.object()
        .shape({
          value: Yup.string().required(strings(t).thisIsARequiredField),
        })
        .required(strings(t).thisIsARequiredField),
      no_of_units: Yup.string()
        .required(strings(t).thisIsARequiredField)
        // Use a regex to validate because it's a string.
        .matches(positiveIntegerRegex, mustBeIntegerErrorMessage),
      unit_of_measure: Yup.object().required(strings(t).thisIsARequiredField),
      custom_packaging_type: Yup.object().required(
        strings(t).thisIsARequiredField
      ),
      price_per_unit: Yup.string()
        .nullable()
        .required(strings(t).thisIsARequiredField),
      custom_packaging_quantity: Yup.string().required(
        strings(t).thisIsARequiredField
      ),
    })
    .defined();

  const yupSchemaProductSkuPackaging = Yup.object()
    .shape({
      ...yupSharedSchema,
      packaging: Yup.object()
        .shape({
          value: Yup.object().required(strings(t).thisIsARequiredField),
        })
        .required(strings(t).thisIsARequiredField),
      total_quantity: Yup.string().required(strings(t).thisIsARequiredField),
      no_of_units: Yup.string()
        .required(strings(t).thisIsARequiredField)
        // Use a regex to validate because it's a string.
        .matches(positiveIntegerRegex, mustBeIntegerErrorMessage)
        .test("no_of_units", strings(t).priceTierMatchError, (val) =>
          priceTiers.length
            ? !!calculatePriceTiersPricePerUom(
                priceTiers,
                val ? parseFloat(val) : null
              )
            : !!val
        ),
      price_per_unit: Yup.string()
        .nullable()
        .test(
          "price_per_unit",
          strings(t).thisIsARequiredField,
          (val) => !!priceTiers.length || !!val
        ),
    })
    .defined();

  type FormInputsProductSkuPackaging = Yup.InferType<
    typeof yupSchemaProductSkuPackaging
  >;
  type FormInputsNoPackaging = Yup.InferType<typeof yupSchemaNoPackaging>;
  type FormInputsCustomPackaging = Yup.InferType<
    typeof yupSchemaCustomPackaging
  >;

  type FormInputsFromSchemas =
    | FormInputsProductSkuPackaging
    | FormInputsNoPackaging
    | FormInputsCustomPackaging;

  const lazyYupSchema = Yup.lazy<FormInputsFromSchemas>((inputs) => {
    if (
      (inputs as unknown as { packaging: OptionType<string> }).packaging
        .value === "no_packaging"
    ) {
      return yupSchemaNoPackaging;
    }
    if (
      (inputs as unknown as { packaging: OptionType<string> }).packaging
        .value === "custom_packaging"
    ) {
      return yupSchemaCustomPackaging;
    }
    return yupSchemaProductSkuPackaging;
  });

  const bottormOrderFormSchema = Yup.lazy((formValues: any) =>
    Yup.object().shape({
      configured_checkboxes: configured_checkboxes_schema_yup({
        formValues,
        t,
      }),
      is_tax_exempt: Yup.boolean(),
      valid_to_date: Yup.string().required(strings(t).thisIsARequiredField),
      required_eta: Yup.string().required(strings(t).thisIsARequiredField),
      payment_method: Yup.object().shape({
        value: Yup.string().required(strings(t).thisIsARequiredField),
      }),
      ...(formData.customer?.shipping_address_id?.value === "new_address"
        ? {
            ...getRequestAddressSchema(t, true),
            ...(pointOfContactType === "manual"
              ? POCManualFormSchema(t, formValues)
              : POCFormSchema(t)),
          }
        : {}),
      feesList: Yup.array(
        Yup.object().shape({
          name: Yup.string(),
          amount: Yup.number()
            .min(0, t("Must be an integer value"))
            .typeError(strings(t).thisIsARequiredField)
            .required(strings(t).thisIsARequiredField),
        })
      ),
    })
  );

  const customerDetailsMethods = useFormWrapper<CustomerDetailsFormOutput>();

  const productInfoMethods = useFormWrapper<AddOrEditProductFormOutput>({
    resolver: yupResolver(lazyYupSchema),
    defaultValues: {
      packaging: { value: undefined, label: undefined },
    },
  });

  const bottomFormMethods = useFormWrapper({
    resolver: yupResolver(bottormOrderFormSchema),
    defaultValues: {
      feesList: !Boolean(customerSettings?.is_tax_exempt)
        ? [{ name: "Tax", amount: 0, id: "tax_fee" }]
        : [{ name: "", amount: 0 }],
    },
  });

  const updateTaxFeeRef =
    useRef<{ updateTaxFee: (isTaxExempt: boolean) => void }>(null);

  const { watch: customerDetailsWatch } = customerDetailsMethods;
  const {
    watch: productInformationWatch,
    reset: productInfoReset,
    setValue: setProductValue,
    control,
  } = productInfoMethods;

  const { fields, append } = useFieldArray({
    control,
    name: "configured_checkboxes",
  });

  const quoteCheckboxes = configured_checkboxes.filter((item) => item.quote);
  useEffect(() => {
    if (quoteCheckboxes.length > 0 && fields?.length < 1) {
      quoteCheckboxes.forEach((element) => {
        append({ value: false, label: element.name, id: element.id });
      });
    }
  }, [quoteCheckboxes, append, fields]);

  const allCustomerDetailsValues = customerDetailsWatch();

  const {
    shipping_address_id: destinationOption,
    delivery_term: deliveryTermOption,
    payment_term: paymentTermOption,
    buyer_id: buyerOption,
  } = allCustomerDetailsValues;

  const productFormIsDisabled = !(
    !!allCustomerDetailsValues.shipping_address_id?.value &&
    !!allCustomerDetailsValues.delivery_term?.value &&
    !!allCustomerDetailsValues.payment_term?.value &&
    !!allCustomerDetailsValues.buyer_id?.value
  );

  const { packaging: packagingOption } = productInformationWatch(["packaging"]);

  const bottomFormOnSubmit = async <T extends CreateQuoteBottomFormOutput>(
    output: T
  ) => {
    if (!isLoading) {
      setIsLoading(true);
      setFormData((data) => ({ ...data, bottomForm: output }));
      const bottomFormDetails =
        pointOfContactType === "manual"
          ? ((await bottormOrderFormSchema.validate(
              output
            )) as CreateQuoteBottomFormPOCManual)
          : ((await bottormOrderFormSchema.validate(
              output
            )) as CreateQuoteBottomFormPOCForm);
      let newAddress: OptionType<string> | null = null;
      if (destinationOption.value === "new_address") {
        const inputs: LocationInputs = {
          company_name: bottomFormDetails?.company_name ?? "",
          external_id: bottomFormDetails?.external_id ?? "",
          address1: bottomFormDetails.address1,
          address2: bottomFormDetails.address2,
          country: bottomFormDetails.country,
          county: bottomFormDetails.county,
          state: bottomFormDetails.state,
          city: bottomFormDetails.city,
          postal_code: bottomFormDetails.postal_code,
          is_active: true,
          contact_first_name: (
            bottomFormDetails as CreateQuoteBottomFormPOCManual
          ).contact_first_name,
          contact_last_name: (
            bottomFormDetails as CreateQuoteBottomFormPOCManual
          ).contact_last_name,
          email_address: (bottomFormDetails as CreateQuoteBottomFormPOCManual)
            .email_address,
          phone_number: (bottomFormDetails as CreateQuoteBottomFormPOCManual)
            .phone_number,
          country_code: (bottomFormDetails as CreateQuoteBottomFormPOCManual)
            .country_code,
          point_of_contact_id: (
            bottomFormDetails as CreateQuoteBottomFormPOCForm
          ).point_of_contact_id,
        };
        const newLocation = await addLocation({
          inputs,
          pocType: pointOfContactType,
        });
        if (newLocation) {
          newAddress = addressToOption(newLocation);
        }
      }
      const hasAddress =
        destinationOption?.value === "new_address"
          ? Boolean(newAddress)
          : Boolean(destinationOption?.value);
      if (hasAddress) {
        try {
          if (formData.customer && selectedBuyer) {
            const data: SellerCreateQuotePOST = {
              delivery_term_id: formData.customer.delivery_term.value,
              payment_term_id: formData.customer.payment_term.value,
              payment_mode_id: output.payment_method.value,
              buyer_id: formData.customer.buyer_id.value,
              required_eta: output.required_eta,
              shipping_address_id: newAddress
                ? newAddress.value
                : formData.customer.shipping_address_id.value,
              transaction_source:
                (formData.customer.order_place_via?.value as any) ?? undefined,
              is_tax_exempt: output.is_tax_exempt,
              valid_to_date: output.valid_to_date,
              fees_to_add: output.feesList
                ? [
                    ...output.feesList.reduce((fees: Fee[], fee) => {
                      if (!!fee.amount) {
                        const { id, name, amount } = fee;
                        fees.push({
                          id,
                          name,
                          amount,
                          currency: currencyCode || ("USD" as CurrencyCode),
                        });
                      }
                      return fees;
                    }, []),
                  ]
                : [],
              items: [
                ...quoteItems.map((item) => {
                  let sku: any = {};
                  // @ts-ignore
                  if (item.sku && item.sku?.id) {
                    // @ts-ignore
                    sku.id = item.sku?.id;
                  } else {
                    sku.package_volume = item.sku.package_volume;
                    sku.packaging_type_id = item.sku.packaging_type.id;
                    sku.packaging_unit_id = item.sku.packaging_unit.id;
                  }

                  return {
                    product_id: item.product_id,
                    price_per_unit: Number(item.price_per_unit),
                    no_of_units: Number(item.no_of_units),
                    total_quantity: Number(item.total_quantity),
                    applications: item?.product_applications[0]?.id ?? null,
                    ...(item.custom_application
                      ? { custom_application: item.custom_application }
                      : {}),
                    sku,
                  };
                }),
              ],
            };
            await Axios.post(
              `/v1/storefronts/${storefront_id}/transactions/create-quote`,
              {
                transaction_state: "price_received",
                data,
              }
            );

            notifySuccess(t("Quote Sent successfully"));
            setIsLoading(false);
            onSuccess();
          }
        } catch (error) {
          if (
            isAxiosError(error) &&
            error?.response?.data?.status_code === "403"
          ) {
            notifyError(error.response.data.message);
          } else {
            notifyError("There was an error sending the quote", { error });
          }
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    }
  };

  const customerDetailsOnSubmit = async (output: CustomerDetailsFormOutput) => {
    setFormData((data) => ({ ...data, customer: output }));
  };

  const packagingSkuOption =
    packagingOption &&
    // @ts-ignore
    packagingOption?.value !== "no_packaging" &&
    packagingOption?.value !== "custom_packaging"
      ? packagingOption
      : null;

  const currencyCode = customerSettings?.preferred_currency;
  const currency = useCurrency(currencyCode ?? "USD")!;

  useSWR<IPriceTierPaginatedOutput>(
    packagingSkuOption?.value?.id &&
      destinationOption?.value &&
      destinationOption?.value !== "new_address" &&
      deliveryTermOption?.value &&
      paymentTermOption?.value &&
      buyerOption?.value
      ? makeUrlWithParams(endpoints.v1_priceTiers(), {
          seller_id: tenant_id,
          buyer_id: buyerOption.value,
          destination_id: destinationOption.value,
          product_sku_id: packagingSkuOption.value.id,
          delivery_term_id: deliveryTermOption.value,
          payment_term_id: paymentTermOption.value,
          currency: currencyCode,
        })
      : null,
    {
      onError: (error) => {
        notifyError("There was an error checking for price tiers", { error });
      },
      onSuccess: ({ data }) => setPriceTiers(data),
    }
  );

  const productDetailsOnSubmit = async (input: AddOrEditProductFormOutput) => {
    const theProduct = product as Product;

    const sku =
      input.packaging.value === "custom_packaging"
        ? ({
            packaging_type: packagingTypes?.find(
              (item) => item.id === input?.custom_packaging_type?.value
            ) as IPackagingType,
            package_volume: Number(input.custom_packaging_quantity),
            packaging_unit: packagingUnits?.find(
              (item) => item.id === input?.unit_of_measure?.value
            ) as IPackagingUnit,
            erp_system_id: "",
            kind: "Buyer SKU",
          } as InlineDisplayCustomSKU)
        : input.packaging.value;

    const object: OrderItemToBeCreated = {
      price_per_unit:
        input.price_per_unit ??
        // Price tiers will exist if price_per_unit does not.
        calculatePriceTiersPricePerUom(priceTiers!, Number(input.no_of_units)),
      product_applications: input.application?.value
        ? [
            {
              name: input.application.label,
              id: input.application.value as string,
              image_url: "fake",
            },
          ]
        : [],
      ...(input.custom_application
        ? { custom_application: input.custom_application }
        : {}),
      product_id: input.product_id,
      // Is this the correct logic?
      currency: customerSettings?.preferred_currency ?? "USD",
      total_quantity: input.total_quantity,
      no_of_units: input.no_of_units,
      product: {
        name: theProduct.name,
      },
      product_identity: {
        cas_number: theProduct?.identifiers?.cas_number ?? null,
      },
      sku,
      id: uuidv4(),
      entire_product: product as Product,
    };

    const index = quoteItems.findIndex(
      (item) => item.product_id === input.product_id
    );

    if (index >= 0) {
      setQuoteItems((items) =>
        items.map((item, idx) => {
          if (idx === index) {
            return object;
          } else return item;
        })
      );
    } else {
      setQuoteItems((items) => [...items, object]);
    }

    clearProductForm();
    setSelectedItemIndex(null);

    return object;
  };

  const clearProductForm = (): void => {
    productInfoReset();
    setProductValue("packaging", { value: undefined, label: undefined });
    setProductValue("product_id", null);
    setProduct(null);
    setProductValue("application", null);
    setProductValue("price_per_unit", null);
    setPriceTiers([]);
  };

  /**
   * Prefill the product form in edit mode.
   * @param item
   */
  const prefillProductForm = (item: OrderItemToBeCreated): void => {
    if (item.sku.kind === "Buyer SKU") {
      setProductValue("packaging", customPackagingOption);
    } else setProductValue("packaging", convertProductSKUToOption(item.sku));
    setProduct(item.entire_product);
    // Product will exist here if form submits
    // maybe?
    setProductValue("product_id", item.entire_product.id);
    setProductValue(
      "application",
      item.product_applications[0]
        ? convertApplicationToOption(item.product_applications[0])
        : null
    );
    setProductValue("price_per_unit", item.price_per_unit);

    setTimeout(() => {
      setProductValue("disabled_uom", item.sku.packaging_unit?.name);
      // This is here because I ran out of time..
      // useQuantityInputFieldClearer will clobber the values if we don't wait.
      setProductValue("total_quantity", item.total_quantity);
      setProductValue("no_of_units", item.no_of_units);

      // Below is for custom SKU:
      setProductValue(
        "custom_packaging_type",
        packagingTypeOrUnitToOption(item.sku.packaging_type)
      );
      setProductValue("custom_packaging_quantity", item.sku.package_volume);
      setProductValue(
        "unit_of_measure",
        packagingTypeOrUnitToOption(item.sku.packaging_unit)
      );
    });
  };

  return (
    <>
      {/* Title is larger than design but consistent with app. */}
      <TitleReset>{t("Record a Quote")}</TitleReset>
      <Container>
        <FormSection>
          <CardNoMargin>
            {/* "Create Quote Form" */}
            <FormProvider {...customerDetailsMethods}>
              <Form
                noValidate
                onSubmit={customerDetailsMethods.handleSubmit(
                  customerDetailsOnSubmit
                )}
              >
                <SectionTitleNoMargin>
                  {t("Customer Details")}
                </SectionTitleNoMargin>
                <CustomerDetailsForm
                  setSelectedBuyer={setSelectedBuyer}
                  isDisabled={customerDetailsIsDisabled}
                  setCustomerSettings={setCustomerSettings}
                  orderType="quote"
                />
              </Form>
            </FormProvider>
          </CardNoMargin>
          <CardNoMargin selectedBorder={isEditMode}>
            {/* "Create/Edit Product Form" */}
            <FormProvider {...productInfoMethods}>
              <Form
                onSubmit={productInfoMethods.handleSubmit(
                  productDetailsOnSubmit
                )}
                noValidate
              >
                <SectionTitleNoMargin>
                  {t("Choose Products")}
                </SectionTitleNoMargin>
                <AddOrEditProductForm
                  isDisabled={productFormIsDisabled}
                  priceTiers={priceTiers}
                  // fallback is displayed before a buyer is selected.
                  currencyCode={currencyCode ?? "USD"}
                  product={product}
                  setProduct={setProduct}
                  isEditMode={isEditMode}
                  onCancel={() => {
                    setSelectedItemIndex(null);
                    clearProductForm();
                  }}
                  // This will exist by the time this form is interactive.
                  buyer_tenant_id={selectedBuyer?.id as UUID}
                />
              </Form>
            </FormProvider>
          </CardNoMargin>
        </FormSection>
        <MainSection>
          {quoteItems.length > 0 ? (
            <CardNoMargin>
              {/* Cart section */}
              {/* Manually add 15px because the Form component does this for customer details */}
              <SectionTitleNoMargin css={{ marginBottom: "15px" }}>
                {t("Quote Summary")}
              </SectionTitleNoMargin>
              <OrderItemArea>
                {quoteItems &&
                  quoteItems.map((item, index) => (
                    <CreateOrderItemCard
                      isSelected={selectedItemIndex === index}
                      setSelectedItemIndex={setSelectedItemIndex}
                      item={item}
                      index={index}
                      editItem={(item) => prefillProductForm(item)}
                      removeItem={(item) => {
                        setQuoteItems((items) =>
                          items.filter((i) => i.id !== item.id)
                        );
                      }}
                    />
                  ))}
              </OrderItemArea>
              <FormProvider {...bottomFormMethods}>
                <SubTotalSection>
                  <SubTotalTitle>{t("Subtotal")}</SubTotalTitle>
                  <SubTotalValue>
                    {" "}
                    {formatPrice(
                      quoteItems.reduce(
                        (acc, item) =>
                          (acc +=
                            parseFloat(item.total_quantity) *
                            parseFloat(item.price_per_unit)),
                        0
                      ),
                      customerSettings?.preferred_currency
                    )}
                  </SubTotalValue>
                </SubTotalSection>
                {Boolean(destinationOption) &&
                destinationOption.value === "new_address" &&
                Boolean(selectedBuyer) ? (
                  <AddressWrapper>
                    <RequestAddress
                      methodsOfUseForm={bottomFormMethods}
                      hideBillingAddress
                      showBillingAddressOption={false}
                      showLocationId={true}
                      show_tax_id={true}
                      formTitle={t("Add new address")}
                    />
                    <PointOfContact
                      methodsOfUseForm={bottomFormMethods}
                      pointOfContactType={pointOfContactType}
                      setPointOfContactType={setPointOfContactType}
                      defaultExistingUser={getUserDetailsFromTenant(
                        selectedBuyer!
                      )}
                    />
                  </AddressWrapper>
                ) : (
                  <></>
                )}
                <TermsAndFeesContainer>
                  <BottomFormArea>
                    <>
                      <SectionTitleNoMargin>
                        {t("Your Terms")}
                      </SectionTitleNoMargin>
                      <Form
                        noValidate
                        onSubmit={bottomFormMethods.handleSubmit(
                          bottomFormOnSubmit
                        )}
                      >
                        <CreateQuoteBottomForm
                          configuredCheckboxFields={fields}
                          isTaxExempt={customerSettings?.is_tax_exempt ?? false}
                          updateTaxFee={(isTaxExempt) =>
                            updateTaxFeeRef.current?.updateTaxFee(isTaxExempt)
                          }
                          defaultPaymentMethod={
                            customerSettings?.default_payment_mode
                              ? convertPaymentModeToOption(
                                  customerSettings?.default_payment_mode
                                )
                              : null
                          }
                        />
                        <PrimaryButtonLarge
                          type="submit"
                          loading={isLoading}
                          onClick={() => {
                            customerDetailsMethods.handleSubmit(
                              customerDetailsOnSubmit
                            )();
                          }}
                        >
                          {t("Send Quote")}
                        </PrimaryButtonLarge>
                      </Form>
                    </>
                  </BottomFormArea>
                  <QuoteFeesContainer>
                    <QuoteFees
                      methodsOfUseForm={bottomFormMethods}
                      subtotal={quoteItems.reduce(
                        (acc, item) =>
                          (acc +=
                            parseFloat(item.total_quantity) *
                            parseFloat(item.price_per_unit)),
                        0
                      )}
                      currency={currency}
                      ref={updateTaxFeeRef}
                    />
                  </QuoteFeesContainer>
                </TermsAndFeesContainer>
              </FormProvider>
              {/* Submit cart form. */}
            </CardNoMargin>
          ) : (
            // TODO correct font size for body when it exists
            <CardNoMargin style={{ lineHeight: "28px", fontSize: "19px" }}>
              <SectionTitleNoMargin>{t("Quote Summary")}</SectionTitleNoMargin>
              <p>{t("To record a quote follow these steps")}</p>
              <ol type="1">
                <li>{t("Choose your customer and review the details")}</li>
                <li>{t("Add products to the cart")}</li>
                <li>{t("Review the cart and send a quote")}</li>
              </ol>
            </CardNoMargin>
          )}
        </MainSection>
      </Container>
    </>
  );
};
