import React, {
  Children,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CaretDownIcon, HamburgerIcon, XIcon } from "../components/Icons/Icons";
import ReactDOM from "react-dom";
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../components/Auth";
import { useRoutePath } from "./Routing";
import { useMediaQueries, useStoreState } from "./util";

import type { ReactElement, ReactNode } from "react";
import styled, { ThemeContext } from "styled-components/macro";
import {
  InvisibleButton,
  PrimaryButtonFitContainer,
} from "../components/Buttons/Buttons";
import { screenSize } from "../theme";
import type { CustomPageConfig } from "../types/types";
import { useOnClickOutside } from "./hooks";
import { NavItemWrapper } from "../layout/shared";

interface IHamburgerWrapperProps {
  isOpen: boolean;
}

interface ITopNavProps {
  isMediumScreen: boolean;
  isMenuVisible: boolean;
}

interface ITopNavItemProps {
  isVisible: boolean;
}

interface IDropDownMenu {
  menuX?: number;
  menuY?: number;
}

interface NavItem extends Partial<CustomPageConfig> {
  nav_link_text: string | null;
  path: string;
  priority?: number;
}

const TopNavWrapper = styled.div<ITopNavProps>`
  display: ${({ isMediumScreen }) => (isMediumScreen ? "none" : "flex")};
  overflow: hidden;
  width: 100%;
  min-width: 40px;
  margin: 3px 10px 3px;
  position: relative;
  justify-content: ${({ isMenuVisible }) =>
    isMenuVisible ? "left" : "center"};
  opacity: ${({ isMediumScreen }) => (isMediumScreen ? 0 : 1)};
  & > div > div {
    height: 100%;
  }
`;

const HamburgerWrapper = styled.div<IHamburgerWrapperProps>`
  order: 99;
  left: 100%;
  background-color: white;
  position: sticky;
  padding: ${({ isOpen }) => (isOpen ? "4px 0 2px" : "6px 2px 2px")};
  border: ${({ isOpen, theme }) =>
    isOpen ? `1px solid ${theme.primaryBorder}` : `1px solid transparent`};
  border-radius: 4px;
  margin-right: 15px;
  align-self: center;
  @media ${screenSize.medium} {
    margin-right: 5px;
  }
`;

const GuestUserWrapper = styled.div`
  display: flex;
  padding: 15px 0 10px;
  flex-direction: column;
  justify-content: space-evenly;
`;
const TopNavItem = styled.div<ITopNavItemProps>`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
`;
const DropDown = styled.div<IDropDownMenu>`
  position: absolute;
  top: 50px;
  right: ${({ menuX }) => `${menuX}px` ?? "255px"};
  min-width: 180px;
  padding: 5px 15px;
  border: 1px solid #e7e8ea;
  background: #fff;
  box-shadow: 0px 6px 16px -6px rgba(0, 0, 0, 0.44);
  border-radius: 5px;
  z-index: 1;
  @media (max-width: 580px) {
    right: 0;
    left: 0;
  }
  button {
    display: block;
    width: 100%;
    background: #fff;
    border-radius: 0;
    border: 1px solid #fff;
    cursor: pointer;
    padding: 10px 0;
    text-align: left;
    &:hover {
      background: #f5f7f8;
    }
  }
  a {
    padding: 8px 0 6px;
    border-bottom: 4px solid transparent;
  }
  a:hover {
    background: ${({ theme }) => `${theme.primaryBG}`};
  }
  a.active {
    border-right: 0;
    border-bottom: ${({ theme }) => `4px solid ${theme.brandColor}`};
    background: ${({ theme }) => `${theme.primaryBG}`};
  }
`;

const LoginButton = styled(Link)`
  background: ${({ theme }) => theme.secondaryBG};
  text-align: center;
  padding: 10px 20px;
  border-radius: 3px;
  margin: 10px 0 0;
  text-decoration: none;
  text-wrap: nowrap;
  font-size: ${(props) => props.theme.fontSizes.medium};
  color: ${(props) => props.theme.primaryTextColor};
  @media ${screenSize.small} {
    padding: 10px;
  }
`;

const RegisterButton = styled(PrimaryButtonFitContainer)`
  text-align: center;
  border: 2px solid ${(props) => props.theme.selectedBorder} !important;
  border-radius: 4px;
  font-weight: ${(props) => props.theme.fontWeights.medium};
  padding: 10px 20px;
  box-shadow: 0px 0px 8px #00000040;
  text-decoration: none;
  @media ${screenSize.small} {
    padding: 5px;
  }
`;

const DropdownContainer = styled.div.attrs({ className: "dropdown-container" })`
  position: relative;
  display: inline-block;
`;

const DropdownButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 6px 20px;
  font-size: ${({ theme }) => theme.fontSizes.medium};

  &:hover {
    color: ${({ theme }) => theme.brandColor};
  }
`;

export function IntersectionObserverWrapper({
  children,
}: {
  children: ReactNode;
}) {
  const navRef = useRef<HTMLDivElement>(null);
  const { isMediumScreen } = useMediaQueries();
  const {
    storefront_metadata: { custom_pages, route_configuration },
    slug,
  } = useStoreState();
  const [visibilityMap, setVisibilityMap] = useState<{
    [key: string]: boolean;
  }>({});

  const orderedChildren = () => {
    const childrenArray = Children.toArray(children);

    if (!custom_pages?.length && !route_configuration?.length) {
      return childrenArray;
    }

    // Create combined navigation items array
    const navItems: NavItem[] = [
      // Add route_configuration items, excluding any that exist in custom_pages
      ...(route_configuration
        ?.filter(
          (route) =>
            route.enabled &&
            !custom_pages?.some(
              (page) =>
                page.path === `/${route.route}` ||
                page.nav_link_text?.toLowerCase() ===
                  route.translations["en"].toLowerCase()
            )
        )
        .map((route) => ({
          nav_link_text: route.translations["en"],
          path: `/${route.route}`,
          priority: 0,
        })) ?? []),
      // Add custom_pages items as NavItems
      ...(custom_pages ?? []),
    ];

    // First identify all parent-child relationships
    const parentChildMap: Record<string, CustomPageConfig[]> = {};
    navItems.forEach((page) => {
      if ("parent_path" in page && page.parent_path) {
        // Initialize array if this is the first child for this parent

        if (!parentChildMap[page.parent_path]) {
          parentChildMap[page.parent_path] = [];
        }
        parentChildMap[page.parent_path].push(page as CustomPageConfig);
      }
    });

    // Create final ordered array
    return navItems
      .sort((a, b) => (a.priority ?? 0) - (b.priority ?? 0))
      .filter((page) => {
        // Keep items that are either:
        // 1. Regular nav items (no parent_path)
        // 2. Parent items that have children
        const result =
          !("parent_path" in page) &&
          page.nav_link_text &&
          (!("is_parent" in page) ||
            (page.is_parent && parentChildMap[page.path ?? ""]?.length > 0));

        return result;
      })
      .map((page) => {
        const navText = page.nav_link_text;
        if (!navText) return null;

        // If this is a parent with children, render as dropdown
        if (
          "is_parent" in page &&
          page.is_parent &&
          page.path &&
          parentChildMap[page.path]?.length > 0
        ) {
          return React.cloneElement(
            <TopNavItem
              key={page.path}
              data-targetid={navText}
              isVisible={visibilityMap[navText] !== false}
            >
              <TopNavDropdown
                parentName={navText}
                childPages={parentChildMap[page.path]}
                tenantSlug={slug!}
              />
            </TopNavItem>,
            {
              name: navText,
              path: page.path,
            }
          );
        }

        // For regular nav items, find matching child and render with consistent visibility check
        const matchingChild = childrenArray.find(
          (item) => (item as ReactElement)?.props.name === navText
        );

        if (matchingChild) {
          return React.cloneElement(matchingChild as ReactElement, {
            "data-targetid": navText,
            isVisible: visibilityMap[navText] !== false,
          });
        }

        return null;
      })
      .filter(Boolean);
  };

  const handleIntersection = useCallback((entries: any[]) => {
    const updatedEntries: { [key: string]: boolean } = {};
    entries.forEach((entry) => {
      const targetid: string | undefined = entry?.target.dataset.targetid;
      // Check if element is visibile within container
      if (targetid) {
        if (entry.isIntersecting) {
          updatedEntries[targetid] = true;
        } else {
          updatedEntries[targetid] = false;
        }
      }
    });
    setVisibilityMap((prev) => ({
      ...prev,
      ...updatedEntries,
    }));
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: navRef.current,
      threshold: 0.85,
    });

    if (navRef.current) {
      Array.from(navRef.current.children).forEach((item) => {
        observer.observe(item);
      });
      return () => {
        setVisibilityMap({});
        observer.disconnect();
      };
    }
  }, [handleIntersection, children]);

  return (
    <>
      <TopNavWrapper
        ref={navRef}
        isMediumScreen={isMediumScreen}
        isMenuVisible={Object.values(visibilityMap).some((v) => v === false)}
      >
        {React.Children.map(orderedChildren(), (child) => {
          return (
            <NavItemWrapper
              data-targetid={(child as React.ReactElement)?.props["name"]}
            >
              {child}
            </NavItemWrapper>
          );
        })}
      </TopNavWrapper>
      <OverflowMenu visibilityMap={visibilityMap}>
        {orderedChildren()}
      </OverflowMenu>
    </>
  );
}

function OverflowMenu({
  children,
  visibilityMap,
}: {
  children: ReactNode;
  visibilityMap: { [key: string]: boolean };
}) {
  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [menuX, setMenuX] = useState<number>();
  const [expandedItems, setExpandedItems] = useState<Set<string>>(new Set());
  const open = Boolean(anchorEl);
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();
  const history = useHistory();
  const { storefront_metadata } = useStoreState();
  const { user } = useAuthContext();
  const { accountPath, storePath } = useRoutePath();
  const { isMediumScreen } = useMediaQueries();
  const { route_configuration } = storefront_metadata;
  const registrationRoute = route_configuration.find(
    (route) => route.route === "registration"
  );

  const location = useLocation();
  const { tenantSlug } = useParams<{ tenantSlug: string }>();

  const isProductDetailPage = (name: string) => {
    return (
      name === t("Portfolio") &&
      (location.pathname.indexOf(`${accountPath}/product/`) === 0 ||
        location.pathname.indexOf(`${storePath}/product/`) === 0)
    );
  };
  const isActive = (name: string, path: string) => {
    const tenantPath = path.replace(/:tenantSlug/, tenantSlug);
    return (
      isProductDetailPage(name) ||
      (location.pathname === tenantPath &&
        !isProductDetailPage(name) &&
        tenantPath !== storePath)
    );
  };

  const handleClick = (event: React.MouseEvent) => {
    if (anchorEl) {
      handleClose();
    } else {
      setAnchorEl(event.currentTarget);
      setMenuX(
        window.innerWidth -
          event.currentTarget.getBoundingClientRect().right -
          5
      );
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (open && menuRef.current && !menuRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [open]);

  const shouldShowMenu = useMemo(
    () =>
      isMediumScreen || Object.values(visibilityMap).some((v) => v === false),
    [visibilityMap, isMediumScreen]
  );

  useEffect(() => {
    setMenuX(
      buttonRef.current?.getBoundingClientRect().right &&
        menuRef.current?.getBoundingClientRect().width
        ? window.innerWidth - buttonRef.current?.getBoundingClientRect().right
        : 250
    );
  }, [buttonRef, menuRef, shouldShowMenu]);

  useEffect(() => {
    window.addEventListener("resize", () =>
      setMenuX(
        buttonRef.current?.getBoundingClientRect().right &&
          menuRef.current?.getBoundingClientRect().width
          ? window.innerWidth - buttonRef.current?.getBoundingClientRect().right
          : 250
      )
    );
  }, []);

  const checkIsActive: () => boolean[] = () => {
    return React.Children.map(children as ReactElement, (child) => {
      if (
        visibilityMap[child?.props["name"]] === false &&
        isActive(child?.props.name, child.props.path)
      ) {
        return true;
      }
      return null;
    });
  };

  const toggleDropdown = (parentName: string) => {
    setExpandedItems((prev) => {
      const next = new Set(prev);
      if (next.has(parentName)) {
        next.delete(parentName);
      } else {
        next.add(parentName);
      }
      return next;
    });
  };

  if (!shouldShowMenu) {
    return null;
  }
  return (
    <>
      <HamburgerWrapper ref={buttonRef} isOpen={open}>
        <InvisibleButton aria-label={t("more")} onClick={handleClick}>
          {open ? (
            <XIcon width={22} height={22} />
          ) : (
            <HamburgerIcon
              width={22}
              height={22}
              fill={
                checkIsActive().includes(true) ? theme.brandColor : undefined
              }
            />
          )}
        </InvisibleButton>
      </HamburgerWrapper>
      {open &&
        ReactDOM.createPortal(
          <DropDown ref={menuRef} menuX={menuX}>
            {React.Children.map(children as ReactElement, (child) => {
              if (visibilityMap[child?.props["name"]] === false) {
                if (child.props.children?.type === TopNavDropdown) {
                  const dropdownProps = child.props.children.props;
                  const isExpanded = expandedItems.has(
                    dropdownProps.parentName
                  );
                  return (
                    <div>
                      <button
                        onClick={() => toggleDropdown(dropdownProps.parentName)}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <span>{dropdownProps.parentName}</span>
                        <span
                          style={{
                            transform: isExpanded
                              ? "rotate(0deg)"
                              : "rotate(-90deg)",
                            transition: "transform 0.2s ease",
                            display: "inline-block",
                          }}
                        >
                          <CaretDownIcon width={12} height={12} />
                        </span>
                      </button>
                      {isExpanded &&
                        dropdownProps.childPages.map(
                          (page: CustomPageConfig) => (
                            <button
                              key={page.path}
                              onClick={() => {
                                history.push(
                                  `/store/${dropdownProps.tenantSlug}${page.path}`
                                );
                                handleClose();
                              }}
                              style={{ paddingLeft: "24px" }}
                            >
                              {page.nav_link_text}
                            </button>
                          )
                        )}
                    </div>
                  );
                }
                return (
                  <div onClick={handleClose}>{React.cloneElement(child)}</div>
                );
              }
              return null;
            })}
            {!user && !registrationRoute?.enabled && isMediumScreen && (
              <GuestUserWrapper>
                <LoginButton to={`${storePath}/login`}>
                  {t("Sign In")}
                </LoginButton>
              </GuestUserWrapper>
            )}
            {!user && registrationRoute?.enabled && isMediumScreen && (
              <GuestUserWrapper>
                <RegisterButton as="a" href={`${storePath}/register`}>
                  {t("Register")}
                </RegisterButton>
                <LoginButton to={`${storePath}/login`}>
                  {t("Sign In")}
                </LoginButton>
              </GuestUserWrapper>
            )}
          </DropDown>,
          document.body
        )}
    </>
  );
}

interface TopNavDropdownProps {
  parentName: string;
  childPages: CustomPageConfig[];
  tenantSlug: string;
}

export function TopNavDropdown({
  parentName,
  childPages,
  tenantSlug,
}: TopNavDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [menuX, setMenuX] = useState<number>();

  useOnClickOutside(dropdownRef, (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (!target.closest('a[data-dropdown-link="true"]')) {
      setIsOpen(false);
    }
  });

  useEffect(() => {
    if (isOpen && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setMenuX(window.innerWidth - rect.right - 5);
    }
  }, [isOpen]);

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownButton ref={buttonRef} onClick={() => setIsOpen(!isOpen)}>
        {parentName}
        <CaretDownIcon width={12} height={12} />
      </DropdownButton>
      {isOpen &&
        ReactDOM.createPortal(
          <DropDown menuX={menuX}>
            {childPages.map((child) => (
              <Link
                key={child.path}
                to={`/store/${tenantSlug}${child.path}`}
                data-dropdown-link="true"
                //   e.preventDefault();
                //   history.push(`/store/${tenantSlug}${child.path}`);
                //   setIsOpen(false);
                // }}
                style={{
                  display: "block",
                  width: "100%",
                  padding: "10px 0",
                  textDecoration: "none",
                  color: "inherit",
                  cursor: "pointer",
                }}
              >
                {child.nav_link_text}
              </Link>
            ))}
          </DropDown>,
          document.body
        )}
    </DropdownContainer>
  );
}
